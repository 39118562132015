.profile-popup
  background-color: #fff
  border-radius: 16px
  padding: 20px 24px
  max-width: 328px
  min-height: 216px
  display: flex
  flex-direction: column
  justify-content: space-between

  .close-btn
    background-color: rgba(196, 196, 196, 0.24)
    &:hover
      background-color: rgba(196, 196, 196, 0.6)

  &__title
    display: flex
    justify-content: space-between

  &__subtitle
    font-size: 16px

  &__message
    font-size: 16px
    line-height: 21px
    font-weight: 500
    margin-top: 16px
    margin-bottom: 24px

  &__buttons-wrapper
    display: flex
    justify-content: space-between

  &__button
    min-height: 40px
    height: 40px
    min-width: 132px
    background-color: #6ABC50

    &--cancel.primary-btn
      background-color: #FF0000

      &:hover, &:focus, &:active
        background-color: #fd3d3d !important
        border-color: #fd3d3d !important

      &:focus
        box-shadow: 0 0 0 0.2rem rgba(253, 61, 61, 0.05) !important