.knowledge-base-list
  list-style: none
  padding: 0
  margin: 0

  &__item
    min-height: 24px
    display: flex
    align-items: center
    padding: 0
    margin-bottom: 4px

    &__link, &__link:hover
      color: $color-blue
      //letter-spacing: 0.5px