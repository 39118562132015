.field-wrapper
  display: flex

  & > :first-child
    margin-right: 8px

textarea.field.form-control
  height: 128px
  max-width: 100%

.form-control.field, .field
  height: 32px
  border-color: #ccc
  font-size: 14px
  line-height: 19px
  resize: none
  appearance: none
  max-width: 328px

  &::placeholder
    font-size: 14px
    line-height: 19px
    color: #ccc

  &:focus
    border-color: #0038FF
    box-shadow: none

  &[name="password"]
    padding-right: 32px

  &[readonly]
    background-color: #fff

  &::-ms-reveal, &::-ms-clear
    display: none

  &.is-invalid
    border-color: red
    background-image: none

    &:focus
      border-color: #0038FF

    &[name="password"],
    &[name="passwordConfirm"]
      padding-right: 52px

  &--select
    background-image: url("../../assets/icon-dropdown.svg")
    background-repeat: no-repeat
    background-size: 10px auto
    background-position: calc(100% - 8px) center
    max-width: 227px

  &--checkbox
    min-height: 24px
    display: flex
    align-items: center
    padding-left: 28px

    .form-check-input
      opacity: 0
      z-index: -999
      margin-left: -28px

      &:checked+.form-check-label

        &:before
          background-color: $color-green

        &:after
          background-image: url("../../assets/checkbox-checked.svg")
          background-repeat: no-repeat
          background-size: 60%
          background-position: center

    .form-check-label
      position: relative

      &:before, &:after
        position: absolute
        content: ''
        top: 2px
        left: -28px
        width: 16px
        height: 16px

      &:before
        border-radius: 2px
        border: 1px solid #ccc

  &__desc
    font-weight: 500
    min-height: 24px
    display: inline-flex
    align-items: center
    color: #757575

  &__preview
    display: flex
    max-width: 100%
    border: 1px solid #ccc
    position: relative
    border-radius: 4px

    &__image
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: inherit

    &__delete
      position: absolute
      top: 2px
      right: 2px


    &--avatar
      border-radius: 50%
      max-width: 72px
      height: 72px
      display: flex
      align-items: center
      justify-content: center

      .field__preview__delete
        top: 0
        right: 0

.form-control-file.field

  &--file
    position: absolute
    height: 0
    width: 0
    opacity: 0
    z-index: -999