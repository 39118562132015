.templates

  &__buttons-wrapper
    position: fixed
    bottom: 32px
    left: 0
    right: 0
    z-index: 1
    width: 100%
    max-width: 484px
    margin: 0 auto
    padding: 0 16px

    @media (min-width: 1200px)
      position: static
      z-index: unset
      max-width: 100%
      padding: 0

  &__button
    width: 100%

  &__popup
    max-width: 328px
    border-radius: 16px
    background-color: #fff
    padding: 16px

    &__title
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 24px

      & .pfm-subtitle
        margin-bottom: 0

    &__text
      margin-bottom: 8px

    &__form

      &__button
        min-height: 40px
        max-height: 40px
        width: 100%

      & .form-group
        margin-bottom: 16px