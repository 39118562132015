.page-wrapper
  padding: 16px 0 0
  height: 100%

  @media (min-width: 992px)
    padding: 24px 12px 0

  & > *
    padding-bottom: 16px

    @media (min-width: 992px)
      padding-bottom: 24px