.pfm-alert
  position: fixed
  bottom: 0
  left: 0
  right: 0
  margin: 0 auto 16px
  max-width: 85%
  z-index: -999
  font-size: 14px

  &--shown
    z-index: 9999

  & .alert
    margin-bottom: 6px

  @media (min-width: 576px)
    max-width: 300px

    & .alert
      margin-bottom: 10px