.create-page

  &__col

    @media (min-width: 992px)
      margin-bottom: 24px

  & .custom-col:last-child .template-type
    margin-bottom: 0

    @media (min-width: 768px)
      margin-bottom: 8px

    @media (min-width: 992px)
      margin-bottom: 0