.load
  display: flex
  align-items: center

  &__button
    min-height: 32px
    min-width: 103px
    display: inline-flex
    justify-content: center
    align-items: center
    text-align: center
    font-weight: 600
    padding: 4px 16px
    background-color: #ddd
    transition: $bootstrap-btn-transition
    border-radius: 4px
    margin: 0
    cursor: pointer

    &:hover
      background-color: #c8c8c8

    & span
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

  &__preview
    border-radius: 50%
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    margin-right: 12px

    &__image
      object-fit: cover
      width: 100%
      height: 100%