.profile

  &__form

    margin-bottom: 24px

    & .form-group:last-of-type
      margin-bottom: 24px

    &__avatar
      object-fit: cover
      width: 100%
      height: 100%
      border-radius: inherit

      &-field
        display: flex
        align-items: center
        padding-top: 6px

      &-wrapper
        width: 64px
        height: 64px
        border-radius: 50%
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        margin-right: 16px

    &__save-btn
      min-width: 156px