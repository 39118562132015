.knowledge-wrapper
  padding-right: 16px
  padding-left: 16px
  display: flex
  flex-wrap: wrap
  @media (min-width: 576px)
    padding-right: 12px
    padding-left: 12px
  @media (min-width: 1099px)
    flex-wrap: nowrap

  .knowledge
    font-family: "Manrope", sans-serif
    margin-right: 0
    @media (min-width: 768px)
      margin-right: 24px

    &__divider
      display: block
      margin-top: 16px
      @media (min-width: 1038px)
        display: none

    &-image
      margin-bottom: 24px
      width: 100%

    &-text
      font-weight: 400
      font-size: 14px
      line-height: 19px
      max-width: 484px
      margin-bottom: 16px

    &-base
      display: flex
      flex-direction: row
      max-width: 396px
      justify-content: space-between
      &-list
        min-width: 151px
        @media (min-width: 576px)
          min-width: 141px
        &__item
          &__link
            line-height: 28px

  .feedback
    width: 100%
    @media (min-width: 500px)
      width: 484px

    &__form
      flex-wrap: wrap
      margin-bottom: 16px

      @media (min-width: 576px)
        flex-wrap: nowrap
        margin-bottom: 0

    .form-group
      width: 100%
      margin-bottom: 10px
      @media (min-width: 576px)
        margin-bottom: 16px

    .field
      min-width: 100%
      width: 100%
      margin-bottom: 4px

      &__desc
        color: #000000
        font-weight: 400

    &__divider
      border-bottom: 2px dotted #c2c2c2
      margin-bottom: 24px

    &-btn
      &__send
        color: #000000
        margin-left: 0
        min-width: 106px
        font-weight: 600
        height: 32px
        display: flex
        align-items: center
        justify-content: center
        @media (min-width: 576px)
          margin-left: 7px
    &-text
      padding: 8px 16px
      margin-bottom: 0
      line-height: 20px
      font-weight: 400

    &-faq
      &_btn
        color: #000000
        text-decoration: none
        max-width: 120px
        font-weight: 600
        height: 32px
        display: flex
        align-items: center
        justify-content: center
      .arrow--opened
        transform: rotate(-180deg)
      &_title
        display: flex
        justify-content: space-between
        &:hover
          cursor: pointer
      &_question
        border: 1px solid #CCCCCC
        border-radius: 4px
        box-sizing: border-box
        margin-bottom: 8px
        &:last-child
          margin-bottom: 16px
      &_answer
        border-top: 1px solid #CCCCCC
        color: #666666