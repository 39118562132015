.wizard

  &__header
    margin-bottom: 26px

    &__cover
      display: flex
      flex-direction: column
      height: 128px
      border-radius: 8px
      color: #fff
      margin-bottom: 16px
      background-color: #ccc
      justify-content: space-between
      padding: 14px 16px 7px
      background-repeat: no-repeat
      background-position: center
      background-size: cover

  &__title
    text-transform: uppercase
    font-size: 24px
    font-weight: 800

  &__form

    &__buttons-wrapper
      display: flex
      margin-top: 40px

      &--mobile-fixed
        position: fixed
        bottom: 32px
        left: 0
        right: 0
        z-index: 1
        width: 100%
        max-width: 484px
        margin: 0 auto
        padding: 0 16px

        & .wizard__form__btn
          width: 100%

        @media (min-width: 1200px)
          position: static
          z-index: unset
          max-width: 100%
          padding: 0
          margin: 20px 0 0 0

          & .wizard__form__btn
            width: auto

    &__btn
      min-height: 40px
      min-width: 93px
      padding: 6px 16px
      font-weight: 500

      &:last-child
        margin-left: auto

  &-popup
    max-width: 328px
    border-radius: 16px
    background-color: #2E61E2
    padding: 22px 24px 24px
    color: #fff
    background-image: url("../../assets/bg-wizard-popup.svg")
    background-position: right bottom
    background-repeat: no-repeat

    &__title
      text-transform: uppercase
      text-align: center
      margin-bottom: 16px

    &__text
      margin-bottom: 26px

    &__buttons-wrapper
      display: flex
      justify-content: space-between

    &__button
      font-size: 14px
      line-height: 19px
      min-height: 40px
      min-width: 132px

      &.btn-outline-primary, &.btn-primary

        &:focus, &:not(:disabled):not(.disabled):active:focus
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2)

      &.btn-outline-primary
        color: #fff
        border-color: #fff

        &:hover
          background-color: rgba(255, 255, 255, 0.2)

        &:not(:disabled):not(.disabled):active
          border-color: #fff
          background-color: rgba(255, 255, 255, 0.3)

      &.btn-primary, &.btn-primary:not(:disabled):not(.disabled):active
        color: #000
        background-color: #fff
        border-color: #fff