.pages-list
  list-style: none
  padding: 0
  margin: 0 0 16px 0

  &__item
    transition: $bootstrap-btn-transition
    padding: 13px 16px 15px
    margin-bottom: 16px
    display: flex
    border-radius: 4px
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24)
    background-color: #fff
    cursor: pointer

    &:hover, &--dd-opened
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.44)

    &--stats
      padding: 12px 12px 12px 16px
      flex-direction: column

      &:hover

        .pages-list__item__title
          text-decoration: underline

      .pages-list__item__title
        color: $color-blue
        justify-content: space-between
        margin-bottom: 10px

    &__stats
      display: flex
      align-items: center

      &__chart
        width: 68px
        height: 42px
        margin-right: 24px
        flex: 0 0 auto

      &__item
        display: flex
        flex-direction: column
        flex-basis: 100px

        & span:first-child
          color: #666
          margin-bottom: 10px

        & span:last-child
          font-size: 16px

    &__left
      flex: 1 1 auto
      max-width: calc(100% - 24px)

    &__right
      display: flex
      align-items: center
      position: relative

    &__title
      font-weight: 500
      font-size: 16px
      line-height: 22px
      margin-bottom: 3px
      display: flex
      align-items: center

      & .page-status
        margin-right: 8px

      & span
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap

    &__status-text

      &--published
        color: #228C00

      &--unpublished
        color: #000

      &--edited
        color: $color-blue

    &__status-views img
      margin: 0 4px 0 16px

    &__actions-btn
      display: flex
      appearance: none
      border: none
      padding: 4px 10px
      background-color: transparent
      cursor: pointer

      &:focus
        outline: none