.default

  &__header
    display: flex
    justify-content: center
    margin-bottom: 56px
    height: 64px
    border-bottom: 1px solid #ddd
    background-color: #fff

  &__body
    max-width: 264px
    margin: 0 auto

    &--max-width
      max-width: 100%

  &__error
    display: flex
    flex-direction: column
    text-align: center
    font-size: 1rem
    line-height: 1.375rem
    font-weight: 500

    .pfm-anchor
      background-color: transparent
      border: none
      box-shadow: none
      appearance: none
      padding: 0

      &:focus
        outline: none

  &__loading
    display: flex
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    align-items: center
    justify-content: center

  &__logo
    max-width: 128px
    height: auto

  &__title
    text-align: center
    margin-bottom: 42px

  &__socials
    display: flex
    flex-wrap: wrap
    justify-content: center

    &-wrapper
      margin-bottom: 26px

    &-title
      font-weight: 500
      margin-bottom: 19px
      text-align: center

  &__social-link
    display: flex
    align-items: center
    justify-content: center
    height: 48px
    width: 100%
    max-width: 80px
    background: #ccc
    border-radius: 4px
    appearance: none
    box-shadow: none
    border: none

    &--disabled
      cursor: default
      opacity: 0.5

  &__divider
    display: flex
    align-items: center
    text-align: center
    font-weight: 500
    margin-bottom: 13px

    &:before, &:after
      content: ''
      flex: 1
      border-bottom: 1px solid #ddd

    &:before
      margin-right: 12px

    &:after
      margin-left: 12px

  &__form
    margin-bottom: 28px

  &__form__label.form-label
    margin-bottom: 0
    font-weight: 500

  &__form__reset-link
    text-align: right
    margin-bottom: 19px
    margin-top: 2px

  &__link
    text-decoration: underline

    &:hover
      text-decoration: none

  &__form__submit.btn
    width: 100%

  &__bottom
    text-align: center
    margin-bottom: 24px

  &__form-register-help
    margin-bottom: 16px

    & > *
      font-size: 14px
      line-height: 18px

    & small:last-child
      margin-top: 16px

  &__homepage-link
    text-align: center

  &__landing-link
    display: flex
    align-items: center