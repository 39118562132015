.select
  height: 32px
  width: 100%
  max-width: 328px

  & &__control
    min-height: 0
    border-color: #ccc
    transition: $bootstrap-border-transition, $bootstrap-box-shadow-transition
    height: 100%

    &--is-focused, &--menu-is-open
      border-color: #0038FF
      box-shadow: none

      &:hover
        border-color: #0038FF

  & &__indicator
    padding: 0

    &-separator
      display: none

  & &__dropdown-indicator
    height: 100%
    width: 24px
    display: flex
    align-items: center
    justify-content: center

    svg
      width: 10px !important
      height: auto !important

  & &__option

    &--is-focused
      background-color: #eee

    &--is-selected
      background-color: #ddd
      color: #000

    &:active
      background-color: #efefef

    &-label
      display: flex
      align-items: center

      span
        margin-left: 12px

      &--no-icon

        span
          margin-left: 28px

      .socials__icon
        width: 16px !important
        height: auto !important

        & > *
          fill: #000

  & &__single-value .select__option-label.select__option-label--no-icon span
    margin-left: 0