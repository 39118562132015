.form

  &__password-group
    position: relative

  &__toggle-password
    width: 32px
    height: 32px
    align-items: center
    justify-content: center
    cursor: pointer
    position: absolute
    bottom: 0
    right: 0
    z-index: 1
    appearance: none
    border: none
    padding: 0
    box-shadow: none
    background-color: transparent

    &:focus
      outline: none

  &-group
    margin-bottom: 18px

  &__help-label
    line-height: 18px
    margin-top: 0.25rem
    margin-bottom: 18px