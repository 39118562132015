@import '~bootstrap/scss/bootstrap'
@import "variables/index"
@import "typography/index"
@import "components/index"

body
  background-color: #fff
  overflow: hidden auto

  &.edit-mode
    overflow: hidden

html:root
  --ck-border-radius: 4px
  --ck-color-toolbar-background: #eee
  --ck-color-button-on-background: #ddd
  --ck-font-face: 'Manrope', sans-serif