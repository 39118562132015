.news-list
  list-style: none
  margin: 0
  padding: 0

  &__item
    display: flex
    flex-direction: column
    padding: 0
    margin-bottom: 16px

    &__link
      display: flex
      flex-direction: column
      color: $color-blue

      &:hover
        color: $color-blue

    &__date
      color: $color-gray
      margin-bottom: 4px

    &__text
      color: $color-blue