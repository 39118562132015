.chart

  &__placeholder
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 100px

  &__legend

    &__item
      display: flex
      align-items: center
      justify-content: space-between

      &__left
        color: #666
        display: flex
        align-items: center

      &__right
        font-size: 16px
        line-height: 22px

      &__color
        border-radius: 50%
        height: 8px
        width: 8px
        margin-right: 8px