.design-type
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  padding-top: 15px
  min-width: 88px
  height: 106px
  cursor: pointer
  appearance: none
  border: none
  background-color: #eee
  transition: $bootstrap-bg-transition, $bootstrap-opacity-transition
  position: relative

  &:focus
    outline: none

  &:hover
    background-color: #fff

  &:disabled
    cursor: default
    color: #000
    opacity: 0.5

    &:hover
      background-color: #eee

  &:active
    color: #000

  &--selected:disabled
    background-color: #fff
    opacity: 1

    &:hover
      background-color: #fff

  &__icon-wrapper
    display: flex
    align-items: center
    justify-content: center
    height: 56px
    width: 56px
    border-radius: 4px
    overflow: hidden
    margin-bottom: 6px

  &__icon
    object-fit: cover
    width: 56px

  &__name
    font-weight: 300

  &__settings__btn
    padding: 6.5px 14px
    position: absolute
    right: 0
    top: 0
    border: none
    border-radius: 4px
    margin: 2px 2px 0 0
    background-color: lightgrey
    display: none
    transition: $bootstrap-bg-transition, $bootstrap-opacity-transition
    &:hover
      background-color: #fff
    &--selected
      display: block
  .menu-popup
    top: 0
    right: 50%