.publish-page
  display: flex
  height: 88px
  padding: 12px 12px 8px
  width: 100%
  background-color: #fff
  flex-direction: column
  justify-content: space-between

  @media (min-width: 992px)
    height: 48px
    padding: 0 12px
    flex-direction: row
    align-items: center
    justify-content: flex-start


  &__controls
    display: flex

    &-wrapper
      display: flex

      @media (min-width: 992px)
        margin-right: 24px

    &__btn
      border: none
      appearance: none
      box-shadow: none
      background-color: transparent
      padding: 0
      margin-left: 8px

      &:focus
        outline: none

  &__url
    display: flex
    align-items: center
    overflow: hidden

    &__status
      display: flex
      align-items: center
      justify-content: center
      width: 24px
      height: 24px
      //margin-right: 4px

    &__text
      max-width: 243px
      background-color: #eee
      display: flex
      align-items: center
      height: 24px
      border-radius: 4px
      padding: 0 4px

      @media (min-width: 992px)
        max-width: 320px

      a
        color: $color-blue
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

  &__buttons-wrapper
    display: flex
    max-width: 328px
    margin-left: 4px

    @media (min-width: 992px)
      max-width: 100%
      margin-left: 0

    &__btn
      height: 32px
      width: 100%
      padding-left: 0
      padding-right: 0
      font-weight: 400
      min-width: 153px

      &:first-child
        margin-right: 8px

      &:last-child
        min-width: 167px

    &__published-text
      margin-bottom: 8px

      @media (min-width: 992px)
        margin-bottom: 0