.block-type
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  padding: 0 12.5px
  height: 72px
  cursor: pointer
  appearance: none
  background-color: #fff
  border: none
  transition: background-color 0.15s ease-in-out

  &:focus
    outline: none

  &:hover
    background-color: #eee

  &:active
    background-color: #ddd
    color: #000

  &__name
    font-weight: 300

  &[disabled]
    cursor: default

    & > *
      opacity: 0.4
      color: #000

    &:hover
      background-color: #fff

  &__icon, &__name
    transition: opacity 0.15s ease-in-out