@font-face
  font-family: 'Manrope'
  src: url('../../fonts/Manrope/Manrope-Light.ttf') format('truetype')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Manrope'
  src: url('../../fonts/Manrope/Manrope-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Manrope'
  src: url('../../fonts/Manrope/Manrope-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Manrope'
  src: url('../../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Manrope'
  src: url('../../fonts/Manrope/Manrope-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Manrope'
  src: url('../../fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype')
  font-weight: 800
  font-style: normal

@font-face
  font-family: 'Droid Sans'
  src: url('../../fonts/DroidSans/DroidSans.ttf')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url('../../fonts/Inter/Inter-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url('../../fonts/Inter/Inter-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url('../../fonts/Inter/Inter-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Inter'
  src: url('../../fonts/Inter/Inter-ExtraBold.ttf') format('truetype')
  font-weight: 800
  font-style: normal

@font-face
  font-family: 'Rubik'
  src: url('../../fonts/Rubik/Rubik-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Rubik'
  src: url('../../fonts/Rubik/Rubik-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Rubik'
  src: url('../../fonts/Rubik/Rubik-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('../../fonts/Roboto/Roboto-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('../../fonts/Roboto/Roboto-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Roboto'
  src: url('../../fonts/Roboto/Roboto-Black.ttf') format('truetype')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: url('../../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: url('../../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Open Sans'
  src: url('../../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'PT Serif'
  src: url('../../fonts/PTSerif/PTSerif-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Lato'
  src: url('../../fonts/Lato/Lato-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Lato'
  src: url('../../fonts/Lato/Lato-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Lato'
  src: url('../../fonts/Lato/Lato-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('../../fonts/CeraPro/CeraPro-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('../../fonts/CeraPro/CeraPro-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('../../fonts/CeraPro/CeraPro-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url('../../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url('../../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url('../../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url('../../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Noto Sans'
  src: url('../../fonts/NotoSans/NotoSans.ttf') format('truetype')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Noto Sans'
  src: url('../../fonts/NotoSans/NotoSans-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Source Sans Pro'
  src: url('../../fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Source Sans Pro'
  src: url('../../fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'TildaSansWeb'
  src: url('../../fonts/TildaSans/tilda-sans_regular.woff2') format('woff2'), url('../../fonts/TildaSans/tilda-sans_regular.woff') format('woff')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'TildaSansWeb'
  src: url('../../fonts/TildaSans/tilda-sans_medium.woff2') format('woff2'), url('../../fonts/TildaSans/tilda-sans_medium.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'TildaSansWeb'
  src: url('../../fonts/TildaSans/tilda-sans_bold.woff2') format('woff2'), url('../../fonts/TildaSans/tilda-sans_bold.woff') format('woff')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'TildaSansWeb'
  src: url('../../fonts/TildaSans/tilda-sans_extra-bold-.woff2') format('woff2'), url('../../fonts/TildaSans/tilda-sans_extra-bold-.woff') format('woff')
  font-weight: 800
  font-style: normal

body
  font-family: 'Manrope', sans-serif
  font-size: 14px
  line-height: 19px

.pfm-title, .pfm-subtitle
  text-transform: uppercase

.pfm-title
  font-weight: 600
  font-size: 24px
  line-height: 33px
  margin-bottom: 0

.pfm-subtitle
  font-weight: 700
  font-size: 20px
  line-height: 27px
  margin-bottom: 16px

.pfm-paragraph
  margin-bottom: 18px

.pfm-text
  line-height: 18px

  &--gray
    color: $color-gray

.pfm-anchor
  text-decoration: underline
  color: $color-blue

  &:hover
    color: $color-blue

  &:hover
    text-decoration: none