.img-helper
  display: flex
  width: 100%
  flex-wrap: wrap
  margin-bottom: 16px

  &__bar, &__item
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    user-select: none
    font-size: 14px
    font-weight: 400
    line-height: 19px

  &__bar
    border-radius: 8px
    box-shadow: 0 1px 6px rgba(58, 60, 122, 0.16)
    background-color: #ffffff

  &__arrow
    margin: 0 6px

.img-helper.img-helper--tabs

  .img-helper__bar
    min-height: 40px
    padding: 8px 10px

    & span
      font-weight: 500
      color: $color-blue

.img-helper.img-helper--tools

  .img-helper__bar
    min-height: 72px
    min-width: 64px
    margin-right: 8px
    padding: 0 6px

.img-helper.img-helper--button

  .img-helper__item
    min-width: 178px
    min-height: 48px
    border: 2px solid #6ABC50
    position: relative
    background-color: #ffffff

  .img-helper__icon
    position: absolute
    right: 0
    bottom: 0

  .img-helper__button
    background-color: $color-blue
    color: #ffffff
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    min-width: 110px
    min-height: 24px
    border-radius: 4px
    padding: 2px 12px