.page-status
  display: flex
  height: 100%
  align-items: center

  &__indicator
    width: 6px
    height: 6px
    display: inline-flex
    border-radius: 50%

  &--published

    .page-status__indicator
      background-color: $color-green

  &--unpublished

    .page-status__indicator
      background-color: #D3B200

  &--edited

    .page-status__indicator
      background-color: $color-blue