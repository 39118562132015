.custom-container
  padding: 0 16px

  @media (min-width: 768px)
    padding: 0 12px

  @media (min-width: 1200px)
    max-width: 100%

.custom-row.row
  margin: 0 -16px

  @media (min-width: 768px)
    margin: 0 -12px

.custom-col
  padding: 0 16px

  @media (min-width: 768px)
    padding: 0 12px

  @media (min-width: 1200px)
    max-width: 508px

  &.col-md-6

    @media (min-width: 1200px)
      max-width: 384px

  &--with-max-width
    max-width: 508px
    margin: 0 auto

    @media (min-width: 1200px)
      margin: 0

.template-type

  &-row
    justify-content: center

    @media (min-width: 768px)
      justify-content: flex-start

  &-col
    max-width: 254px
    min-width: 254px