.menu-popup
  position: absolute
  top: calc(100% + 10px)
  right: 0
  min-width: 192px
  background: #fff
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24)
  border-radius: 4px
  z-index: -999
  opacity: 0
  overflow: hidden
  padding: 12px

  &--opened
    opacity: 1
    z-index: 1

  &__title
    display: flex
    align-items: center
    min-height: 24px
    font-size: 16px
    line-height: 22px
    color: $color-gray
    margin-bottom: 8px

  &__list
    list-style: none
    padding: 0
    margin: 0 0 12px 0

    &:last-child
      margin-bottom: 0

    &__item
      display: flex
      min-height: 24px
      padding: 0
      margin-bottom: 8px
      transition: $bootstrap-bg-transition
      border-radius: 4px

      &:last-child
        margin-bottom: 0

      &:hover
        background-color: #EBEEF2

      &--disabled

        &:hover
          background-color: transparent

      &__icon
        width: 24px
        height: 24px
        margin-right: 8px

      &__text
        font-size: 16px
        line-height: 18px

      &__button
        appearance: none
        border: none
        background-color: transparent
        box-shadow: none
        display: flex
        align-items: center
        padding: 0
        width: 100%

        &:active
          color: #000

        &--center
          justify-content: center

        &--as-link
          color: #000

          &:hover
            color: #000
            text-decoration: none

        &:focus
          outline: none

        &:disabled

          .menu-popup__list__item__icon
            opacity: 0.5