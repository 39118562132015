.tools-panel
  width: 100%
  background-color: #eee
  border-bottom: 1px solid #ddd
  position: relative
  z-index: 1

  &--no-border
    border-bottom-width: 0

  &__tabs
    height: 40px
    border-bottom: 1px solid #ddd
    display: flex
    align-items: center
    overflow-x: auto

  &__tools
    display: flex
    overflow-x: auto

    &--bg-white
      background-color: #fff

  &__tab-button
    display: flex
    appearance: none
    align-items: center
    justify-content: center
    padding: 0 16px
    border: none
    background-color: transparent
    height: 100%
    color: #0038FF
    font-weight: 500
    font-size: 16px

    &:disabled
      color: #000
      background-color: #fff

      & .tools-panel__tab-button__icon
        border-color: #000

    &:focus
      outline: none

    &:active
      color: #000

    &__icon
      display: none
      align-items: center
      justify-content: center
      width: 24px
      height: 24px
      border: 2px solid #0038FF
      border-radius: 50%
      font-weight: 700
      margin-right: 8px

      @media (min-width: 576px)
        display: flex

    & .page-status__indicator
      margin-bottom: 14px
      margin-left: 1px