.form-group

  &--switcher
    display: flex
    align-items: center
    justify-content: space-between

  &--section-switcher
    margin-top: 6px

    &:first-child
      margin-top: 0

  &--stats
    display: flex
    align-items: center

    & .label
      margin: 0 8px 0 0

    & .field-wrapper
      min-width: 164px

  &__hyphen-divider
    margin: 0 8px

  &--select

    & button
      padding: 0 3px

  &--select-icon

    .select
      max-width: 54px