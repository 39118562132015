.template-type
  background-color: #fff
  border-radius: 4px
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24)
  transition: $bootstrap-btn-transition
  padding: 13px 16px 15px
  margin-bottom: 16px
  cursor: pointer

  @media (min-width: 768px)
    margin-bottom: 24px

  @media (min-width: 992px)
    margin-bottom: 0
    height: 100%

  &:hover
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.44)

  &--disabled
    opacity: 0.5
    cursor: default

    &:hover
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24)

  &__title
    font-weight: 500
    font-size: 16px
    line-height: 22px
    margin-bottom: 9px

  &__desc
    display: flex

    &__icon-wrapper
      height: 56px
      min-width: 32px
      margin-right: 16px
      background-color: #cecece
      border-radius: 4px
      display: flex
      align-items: center
      justify-content: center

      & img
        object-fit: cover
        width: 100%
        height: 100%