.editor
  display: flex
  flex-direction: column
  height: calc(100vh - 56px)
  height: calc(var(--vh, 1vh) * 100 - 56px)
  overflow: hidden

  &--edit-mode

    & .tools-panel
      z-index: 0

      @media (min-width: 992px)
        z-index: 2

    & .editor__sidebar
      z-index: 5
      opacity: 1

      @media (min-width: 992px)
        z-index: 2

    & .editor__workspace
      z-index: unset

      @media (min-width: 992px)
        z-index: 0

      &:before
        opacity: 1
        z-index: 5
        transform: translateX(0)
        transition: $opacity--z-index--start, $bootstrap-opacity-transition
        @media (min-width: 992px)
          z-index: 1

  &__main
    display: flex
    overflow: auto
    flex: auto
    background-color: #fff

    &--height-fix
      display: block

  &__sidebar
    flex-basis: 360px
    background-color: #fff
    padding: 16px 23px 6px
    overflow: hidden auto
    position: fixed
    bottom: 0
    left: 0
    right: 0
    max-height: calc(100% - 24px)
    max-width: 576px
    margin: 0 auto
    opacity: 0
    z-index: -1
    border-top-left-radius: 16px
    border-top-right-radius: 16px

    @media (min-width: 992px)
      border-top-left-radius: 0
      border-top-right-radius: 0
      border-left: 1px solid #ddd
      max-height: unset
      max-width: unset
      position: static
      opacity: 1
      flex-basis: 368px
      min-width: 368px
      z-index: 1

    &--popup-opened
      z-index: 9999

    &__title, &__subtitle
      font-size: 16px
      line-height: 22px

    &__title
      font-weight: 700
      text-transform: uppercase
      margin-bottom: 18px
      display: flex
      align-items: center
      justify-content: space-between
      min-height: 40px

      &__controls
        display: flex
        align-items: center

        &__btn.secondary-btn
          height: 24px
          width: 71px
          padding: 0
          line-height: 19px
          margin-right: 16px

          @media (min-width: 992px)
            display: none

    &__subtitle
      color: #757575
      margin-bottom: 13px

    &__image
      max-width: 100%
      margin-bottom: .5rem

      &--margin-fix
        margin-left: -6px

    &__content

      &-section
        margin-bottom: 1.75rem

      & ol
        padding: 0 0 0 20px

    &__btn
      padding: 4px 12px

  &__workspace
    flex: 1 1 auto
    overflow-y: auto
    overflow-x: hidden
    display: flex
    justify-content: center
    align-items: flex-start
    background-color: #DDD
    position: relative
    padding: 46px 0 0
    z-index: 0

    &:before
      position: fixed
      content: ''
      width: 100%
      height: 100%
      top: 0
      left: 0
      transform: translateX(-100%)
      background-color: rgba(0, 0, 0, 0.4)
      transition: $opacity--z-index--end, $bootstrap-opacity-transition, transform 0s .15s
      opacity: 0
      z-index: -1

    &__panel
      display: flex
      align-items: center
      justify-content: space-between
      position: absolute
      top: 0
      left: 0
      width: 100%
      min-height: 32px
      padding: 7px 8px

      &__title
        display: flex
        align-items: center

        &__history-buttons
          display: flex
          align-items: center

          & .editor__workspace__panel__settings__btn

            &:disabled
              opacity: 0.25
              background-color: transparent


          &__divider
            height: 24px
            border-left: 1px solid #c2c2c2
            margin: 0 8px

        &__status
          height: 32px
          width: 32px
          display: flex
          align-items: center
          justify-content: center
          margin-right: 4px

        &__text
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          max-width: 180px

          &__input
            appearance: none
            box-shadow: none
            border-radius: 4px
            border: 1px solid #ccc
            box-sizing: border-box
            padding: 0 6px
            width: 160px

            &:focus
              outline: none
              border-color: $color-blue

      &__settings
        position: relative
        display: flex

        &__btn
          appearance: none
          box-shadow: none
          padding: 0
          border: none
          background-color: transparent
          display: flex
          align-items: center
          justify-content: center
          width: 32px
          height: 32px
          transition: $bootstrap-bg-transition
          border-radius: 3px

          &:hover, &:disabled
            background-color: #fff

          &:focus
            outline: none

        .menu-popup
          right: 12px

    &__page-wrapper
      position: relative
      padding: 0 0 46px

    & .pfm-page
      min-height: 744px
      box-shadow: 0 4px 32px rgba(0, 0, 0, 0.25)

    &--mobile, &--tablet, &--desktop

      & .editor__workspace__page-wrapper
        width: 100%
        max-width: 320px

    &--tablet, &--desktop
      padding: 46px 46px 0
      .pfm-page .perfluence-block--horizontal
        padding: 8px 16px
        .swiper-slide
          max-width: 480px

      & .editor__workspace__page-wrapper

        @media (min-width: 576px)
          max-width: 688px

        & .pfm-page .perfluence-block
          border-radius: 12px
          margin: 0

    &--mobile

      .pfm-page .perfluence-block--horizontal
        padding: 8px 0
        .swiper-slide
          max-width: 280px

      .pfm-page__component
        max-width: 375px

    &--tablet

      .pfm-page__component
        max-width: 640px

      .image-container
        max-width: 288px

        &--justify
          max-width: 100%

      .video-wrapper
        height: 342px

      .background-wrapper
        height: 192px

        &--sm
          height: 156px

        &--lg
          height: 216px

      .avatar-wrapper
        margin-top: -72px
        max-width: 576px

        .avatar__image-wrapper
          min-width: 144px
          max-width: 144px
          height: 144px

        .avatar__username
          font-size: 16px
          margin-bottom: 20px

          &-wrapper
            max-width: calc(100% - 144px)

        &--sm
          margin-top: -54px

          .avatar__image-wrapper
            min-width: 108px
            max-width: 108px
            height: 108px

          .avatar__username-wrapper
            max-width: calc(100% - 108px)

        &--lg
          margin-top: -84px

          .avatar__image-wrapper
            min-width: 168px
            max-width: 168px
            height: 168px

          .avatar__username-wrapper
            max-width: calc(100% - 168px)

        &--photo-disabled
          margin-top: 16px

          .avatar__username
            margin: 0

            &-wrapper
              max-width: 100%
              margin: 0 auto

        &--cover-disabled
          margin-top: 48px


    &--desktop

      .pfm-page__component
        max-width: 960px

      .image-container
        max-width: 448px

        &--justify
          max-width: 100%

      .video-wrapper
        height: 552px

      .background-wrapper
        height: 256px

        &--sm
          height: 208px

        &--lg
          height: 288px

      .avatar-wrapper
        margin-top: -96px
        max-width: 768px

        .avatar__image-wrapper
          min-width: 192px
          max-width: 192px
          height: 192px

        .avatar__username
          font-size: 18px
          margin-bottom: 32px

          &-wrapper
            max-width: calc(100% - 192px)

        &--sm
          margin-top: -72px

          .avatar__image-wrapper
            min-width: 144px
            max-width: 144px
            height: 144px

          .avatar__username-wrapper
            max-width: calc(100% - 144px)

        &--lg
          margin-top: -112px

          .avatar__image-wrapper
            min-width: 224px
            max-width: 224px
            height: 224px

          .avatar__username-wrapper
            max-width: calc(100% - 224px)

        &--photo-disabled
          margin-top: 16px

          .avatar__username
            margin: 0

            &-wrapper
              max-width: 100%
              margin: 0 auto

        &--cover-disabled
          margin-top: 48px

      & .editor__workspace__page-wrapper

        @media (min-width: 1328px)
          max-width: 100%

    &--tab-2

      .editor__component::before
        display: none

  &__helper
    font-size: 16px
    line-height: 19px
    text-align: center
    color: $color-gray
    padding-top: 21px
    font-family: 'Inter', sans-serif

  &__component
    position: relative
    background-color: #fff
    transition: z-index 0.15s
    z-index: 0

    &:before
      position: absolute
      content: ''
      top: 0
      left: 0
      height: 100%
      width: 100%
      border: 2px solid $color-green
      opacity: 0
      transition: $bootstrap-opacity-transition

    &:hover

      @media (min-width: 992px)
        & .editor__component__delete.btn-danger
          display: flex

        &:before
          opacity: 1

    &--clicked

      & .video-wrapper
        position: relative

      @media (min-width: 992px)
        z-index: 2

        & .editor__component__delete.btn-danger
          display: flex

        &:before
          opacity: 1

    &__delete.btn-danger
      z-index: 100
      display: none
      align-items: center
      background-color: #fff
      border: 2px solid #ccc
      border-radius: 4px
      color: #000
      font-size: 11px
      position: absolute
      top: 4px
      right: 4px
      font-family: 'Manrope', sans-serif
      padding: 0
      width: 75px
      height: 24px
      justify-content: center

      &:hover
        border-color: #f00

      &:active
        background-color: #fff !important
        border-color: #f00 !important
        color: #000 !important

      & img
        margin-right: 4px

  &__form
    margin-bottom: 10px

    &__file-load
      display: flex

      &__name
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        word-break: break-word
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical

    &__buttons-wrapper
      display: flex

      & > :first-child
        margin-right: 8px

    &__edit-btn
      margin-bottom: 8px

    &__add-button-wrapper
      margin-bottom: 18px

  &__button.pfm-button
    height: 40px

  &__publication
    padding: 16px
    width: 100%
    max-width: 550px
    &__publish
      &--wrapper
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        min-height: 126px
        background-color: #B1DEFF
        border-radius: 8px
        margin-bottom: 16px

    & .field, & .form-control.field
      max-width: 100%

    &__title
      text-transform: uppercase
      font-size: 16px
      line-height: 22px
      font-weight: 500
      margin-bottom: 16px
      display: flex
      align-items: center

      &--with-pointer
        cursor: pointer

      &__image
        margin-left: auto
        width: 15.41px

        &--closed
          transform: rotate(-90deg)

    &__text
      font-size: 14px
      font-weight: 600
      margin-bottom: 16px

      @media (min-width: 576px)
        font-size: 16px

    &__buttons-wrapper
      //margin-bottom: 24px

      & > :first-child
        margin-right: 8px

    &__button.btn
      font-weight: 400
      height: 40px
      min-width: 120px
      padding: 5px 11px

      @media (min-width: 576px)
        min-width: 153px

    &__url
      margin-bottom: 24px

      &__text
        margin-bottom: 3px

      &__link
        display: flex

        &__status
          display: flex
          align-items: center
          margin-left: -4px

        &__text
          background-color: #eee
          border-radius: 2px
          display: flex
          justify-content: space-between
          align-items: center
          height: 32px
          flex: 1 0 auto
          padding-left: 8px
          max-width: calc(100% - 24px)

          & span
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden

          & a
            color: $color-blue

    &__divider
      border-bottom: 2px dotted #c2c2c2
      margin-bottom: 16px

    &__form

      &__button
        min-width: 108px

        &:first-child
          margin-right: 8px

      &__field-with-domain
        display: flex
        align-items: center

        & span
          margin-right: 4px

      &__advanced
        padding: 8px 0 26px

        &__button
          appearance: none
          background-color: transparent
          box-shadow: none
          padding: 0
          border: none
          color: $color-blue
          font-weight: 600
          text-decoration: underline

          &:focus
            outline: none

          &:hover:not([disabled])
            text-decoration: none

          &[disabled]
            opacity: 0.5

.editor__workspace--tablet

  .pfm-page

    &.pfm-page--instagram

      .gallery

        &__col

          &--1
            height: 610px

          &--2
            height: 305px

          &--3
            height: 203.333px

          &--4
            height: 152.5px

        &__slide
          width: 312px
          height: 312px

    &.pfm-page--atoms,
    &.pfm-page--tiffany,
    &.pfm-page--graphite,
    &.pfm-page--clouds,
    &.pfm-page--autumn,
    &.pfm-page--pink,
    &.pfm-page--eco,
    &.pfm-page--mosaic,
    &.pfm-page--aurora,
    &.pfm-page--minimal,
    &.pfm-page--pastel

      .gallery

        &__col

          &--1
            height: 427px

          &--2
            height: 213.5px

          &--3
            height: 142px

          &--4
            height: 106.75px

        &__slide
          width: 312px
          height: 218.5px

.editor__workspace--desktop

  .pfm-page

    &.pfm-page--instagram

      .gallery

        &__col

          &--1
            height: 930px

          &--2
            height: 465px

          &--3
            height: 310px

          &--4
            height: 232.5px

        &__slide
          width: 500px
          height: 500px

    &.pfm-page--atoms,
    &.pfm-page--tiffany,
    &.pfm-page--graphite,
    &.pfm-page--clouds,
    &.pfm-page--autumn,
    &.pfm-page--pink,
    &.pfm-page--eco,
    &.pfm-page--mosaic,
    &.pfm-page--aurora,
    &.pfm-page--minimal,
    &.pfm-page--pastel

      .gallery

        &__col

          &--1
            height: 651px

          &--2
            height: 325.5px

          &--3
            height: 217px

          &--4
            height: 162.75px

        &__slide
          width: 500px
          height: 350px