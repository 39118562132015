.code-editor
  background-color: #2b2b2b
  color: #bec4cd
  border-radius: 6px
  min-height: 200px
  height: auto!important
  resize: none!important
  padding: 10px

  .codejar-linenumbers div
    padding-left: 6px

  .token.tag, .token.punctuation
    color: #E8BF6A

  .token.attr-name, .token.operator
    color: #bec4cd

  .token.operator
    background: none

  .token.attr-value, .token.punctuation.attr-equals, .token.string
    color: #A5C261

  .token.keyword, .token.boolean
    color: #cc7832

  .token.function
    color: #FFC66D

  .token.class-name, .token.property
    color: #9876CA

  .token.entity
    background: none
    color: #6D9CBE

  .token.number
    color: #6897bb

  .language-css .token.string, .style .token.string, .token.url
    background: none
