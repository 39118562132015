$color-green: #6ABC50
$color-lightgreen: #79C362
$color-gray: #757575
$color-blue: #0038ff
$opacity--z-index--end: z-index 0.15s step-end
$opacity--z-index--start: z-index 0.15s step-start
$bootstrap-opacity-transition: opacity 0.15s ease-in-out
$bootstrap-bg-transition: background-color 0.15s ease-in-out
$bootstrap-btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
$bootstrap-border-transition: border-color 0.15s ease-in-out
$bootstrap-box-shadow-transition: box-shadow 0.15s ease-in-out