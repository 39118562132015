.main-wrapper
  flex: 1 1 auto
  margin-top: 56px
  background-color: #fff
  height: calc(100vh - 56px)
  height: calc(var(--vh, 1vh) * 100 - 56px)
  overflow: auto

  @media (min-width: 1200px)
    margin-left: 240px

  &--editor
    overflow: hidden
    background-color: #DDD