.datetime-picker

  .field
    max-width: 92px
    padding: 4px
    text-align: center

  .rdtPicker
    top: 100%
    left: 0

  &--right

    .rdtPicker
      left: unset
      right: 0

      @media (min-width: 576px)
        left: 0
        right: unset