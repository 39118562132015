.home

  &__loading
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

  &__info
    border-radius: 8px
    color: #fff
    font-weight: 600
    padding: 24px 16px 19px
    margin-bottom: 24px
    background-position: center
    background-repeat: no-repeat
    background-size: cover

    & h1
      font-weight: 800
      font-size: 24px
      line-height: 33px
      margin-bottom: 12px

    & p
      margin-bottom: 8px
      font-weight: 600
      font-size: 1rem
      line-height: 1.375rem

    & a
      color: #fff
      text-decoration: underline

      &:hover
        text-decoration: none

    & :last-child
      margin-bottom: 0

  &__pages

    &__btn-wrapper
      margin-bottom: 16px
      max-width: 254px
      margin-left: 15px

      & .btn
        width: 100%

  &__divider
    border-top: 2px dotted #c2c2c2
    margin: 32px 0 24px

    &--mobile
      margin-top: 6px
      order: 0

      &:first-of-type
        @media (min-width: 1200px)
          display: none