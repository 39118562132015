.editor
  &__sidebar
    &__design
      //form
      .form-size
        width: 88px
        margin-left: 16px

      .field::-webkit-inner-spin-button
          display: none
          -webkit-appearance: none
          margin: 0
      .validation
        font-size: 12px
        white-space: nowrap

      .form-label
        white-space: nowrap

      //select

      .select__bg-color
        max-width: 213px

      .select__text-size
        width: 98px
        margin-left: 16px

      .select__header_text-size
        width: 213px

      .select__image-radius
        max-width: 98px

      .select__social
        margin-left: 16px

        .field-wrapper
          width: 98px

      // design

      .design
        &__editor
          font-size: 14px
          font-weight: 600

          &--subtitle
            font-weight: 400
            font-size: 16px
            line-height: 20px
            color: #000000
            opacity: 0.54
            margin-bottom: 16px