.popup
  position: fixed
  display: flex
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.4)
  z-index: -1
  opacity: 0
  overflow: auto
  transition: $opacity--z-index--end, $bootstrap-opacity-transition

  &--opened
    opacity: 1
    z-index: 10
    transition: $opacity--z-index--start, $bootstrap-opacity-transition

  & > *
    margin: auto
    width: 100%