.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 4
  height: 56px
  background-color: #fff
  border-bottom: 1px solid #ddd
  padding: 8px 16px
  display: flex
  justify-content: space-between

  @media (min-width: 1200px)
    padding: 8px 24px

  &__sidebar-button
    appearance: none
    background-color: transparent
    padding: 0
    border: none
    display: flex
    align-items: center
    justify-content: center
    height: 14px
    width: 20px
    margin-right: 36px

    @media (min-width: 1200px)
      display: none

    &:focus
      outline: none

    &--active .header__sidebar-button__line
      border-color: transparent

      &:before
        transform: rotate(45deg)
        top: 0

      &:after
        transform: rotate(-45deg)
        top: 0

    &__line
      width: 100%
      border-bottom: 2px solid #000
      position: relative

      &:before, &:after
        content: ''
        position: absolute
        width: 100%
        left: 0
        border-bottom: 2px solid #000

      &:before
        top: -6px

      &:after
        top: 6px

  &__logo
    display: flex
    align-items: center

  &__profile
    position: relative

    &-wrapper
      display: flex
      align-items: center

    &__clickable
      display: flex
      align-items: center
      cursor: pointer

    &__name
      font-weight: 400
      font-size: 12px
      margin-right: 8px
      display: none

      @media (min-width: 1200px)
        display: inline

    &__image
      object-fit: cover
      width: 100%
      height: 100%
      border-radius: inherit

      &-wrapper
        width: 32px
        height: 32px
        box-sizing: border-box
        border-radius: 50%

    & .menu-popup
      transform: translateY(calc(-100% - 54px))
      min-width: 128px

      &--opened
        transform: none