.perfluence

  &__back
    color: $color-blue
    font-size: 16px
    line-height: 22px
    background-color: transparent
    padding: 0
    border: none
    margin-bottom: 1.25rem

    &:focus
      outline: none

    &:hover
      text-decoration: underline

  .primary-btn
    min-height: 40px
    font-size: .875rem
    font-weight: 400