$color-atoms: #3064c9
$color-atoms-hover: #517DD2
$color-atoms-active: #2854A9
$color-atoms-secondary: #6196FF

$color-tiffany: #30C9C0
$color-tiffany-hover: #6DD9D3
$color-tiffany-active: #228E87
$color-tiffany-secondary: #F9AE9D

$color-graphite: #131313
$color-graphite-hover: #DDFFFF
$color-graphite-active: #92B4B4
$color-graphite-secondary-btn-hover: #555555
$color-graphite-secondary-btn-active: #1B1B1B
$color-graphite-secondary: #CFFFFF

$color-clouds: #A6C6F7
$color-clouds-secondary: #0036C1
$color-clouds-secondary-hover: #4B71D3
$color-clouds-secondary-active: #002688

$color-autumn: #FFF5EC
$color-autumn-secondary: #F07139
$color-autumn-secondary-hover: #F49166
$color-autumn-secondary-active: #B9572C
$color-autumn-text: #741C00
$color-autumn-btn-text: #b42b00

$gradient-pink: linear-gradient(90deg, #EB5C7A 0%, #CD0E36 100%)
$color-pink: #ce1239
$color-pink-dark: #282021
$color-pink-text: #534D4D

$color-eco: #76BD55
$color-eco-hover: #96CC7C
$color-eco-active: #5B9242
$color-eco-secondary: #F8F6F7
$color-eco-secondary-hover: #EBEBEB
$color-eco-secondary-active: #E8E8E8
$color-eco-dark: #110f1c

$color-mosaic: #5E81F4
$color-mosaic-hover: #839DF6
$color-mosaic-active: #4963BC
$color-mosaic-secondary: #F5F5FB
$color-mosaic-secondary-active: #EAEAF1
$color-mosaic-dark: #272829
$color-mosaic-text: #768492

$color-aurora: #5B6272
$color-aurora-text: #fdfefe
$color-aurora-secondary: #5CB55B
$gradient-aurora: linear-gradient(92deg, #5CB55B 7.79%, #3BB278 62.42%)

$color-minimal: #273a49
$color-minimal-hover: #586772
$color-minimal-active: #1E2D38
$color-minimal-text: #6e7e8b

$color-pastel: #878d7f
$color-pastel-hover: #A3A79D
$color-pastel-active: #696D62
$color-pastel-secondary: #b9885f
$color-pastel-secondary-hover: #CC986A
$color-pastel-secondary-active: #B68357
$color-pastel-bg: #efefef
$color-pastel-text: #636363

.pfm-page, .block-switcher__option, .rich-text-editor
  --text-font-family: inherit
  --text-font-size: 14px
  --text-line-height: 1.2
  --text-margin: 19px
  --text-font-weight: normal

  --title-font-family: inherit
  --title-color: inherit
  --title-font-size: 24px
  --title-font-weight: 500
  --title-line-height: 1.2
  --title-margin: 18px

  --subtitle-font-family: inherit
  --subtitle-color: inherit
  --subtitle-font-size: 18px
  --subtitle-font-weight: 500
  --subtitle-line-height: 1.2
  --subtitle-margin: 17px

.pfm-page, .block-switcher__option
  --font-family: 'Roboto', sans-serif
  --color: #000000

  --button-color: #000000
  --button-bg-color: #ffffff
  --button-border: 1px solid #c8c8c8
  --button-font-family: inherit
  --button-font-weight: 500
  --button-font-size: 16px
  --button-line-height: 1.2
  --button-border-radius: 8px
  --button-height: 48px
  --button-opacity: unset
  --button-box-shadow: none
  --button-transition: opacity .15s ease-out, color .15s ease-out, background .15s ease-out, border-color .15s ease-out, filter .15s ease-out
  --button-color__hover: #000000
  --button-bg-color__hover: #d9d9d9
  --button-border__hover: 1px solid #c8c8c8
  --button-opacity__hover: unset
  --button-filter__hover: unset
  --button-color__active: #000000
  --button-bg-color__active: #c8c8c8
  --button-border__active: 1px solid #c8c8c8
  --button-opacity__active: unset
  --button-filter__active: unset
  --button-color--secondary: #000000
  --button-bg-color--secondary: #ffffff
  --button-border--secondary: 1px solid #c8c8c8
  --button-border-radius--secondary: 8px
  --button-height--secondary: 32px
  --button-font-size--secondary: 14px
  --button-line-height--secondary: 1.3
  --button-background-clip--secondary: unset
  --button-text-fill-color--secondary: unset
  --button-box-shadow--secondary: none
  --button-color--secondary__hover: #000000
  --button-bg-color--secondary__hover: #d9d9d9
  --button-border--secondary__hover: 1px solid #c8c8c8
  --button-opacity--secondary__hover: unset
  --button-filter--secondary__hover: unset
  --button-color--secondary__active: #000000
  --button-bg-color--secondary__active: #c8c8c8
  --button-border--secondary__active: 1px solid #c8c8c8
  --button-opacity--secondary__active: unset
  --button-filter--secondary__active: unset
  --button-desc-color: #666666
  --button-desc-font-family: inherit
  --button-desc-font-size: 14px
  --button-desc-line-height: 1.2
  --button-desc-font-weight: normal
  --button-desc-margin: 9px auto 0

  --link-color: #194FB9
  --link-text-decoration: none
  --link-text-decoration__hover: underline

  --timer-color: #ff0000
  --timer-font-size: 16px
  --timer-font-weight: 400
  --timer-line-height: 1.25
  --timer-font-family: inherit
  --timer-font-size--md: 18px
  --timer-font-weight--md: 700
  --timer-line-height--md: 1.2
  --timer-font-size--lg: 24px
  --timer-font-weight--lg: 700
  --timer-line-height--lg: 1.2

  --image-border-radius: unset

  --commercial-bg-color: #ffd89e
  --commercial-border: none
  --commercial-border-radius: 12px

.pfm-page
  --bg-color: #ffffff
  --page-bg-color: #ffffff

  --footer-color: inherit
  --footer-link-color: #194fb9

  --header-bg-color: #dedede
  --header-bg-image: unset

  --avatar-color: #194fb9
  --avatar-font-weight: 400

  --separator-border-color: #c2c2c2
  --separator-opacity: 0.24
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave.svg") 8 repeat

  --socials-size: 48px
  --socials-icon-size: 24px
  --socials-border: 1px solid #c8c8c8
  --socials-border-radius: 24px
  --socials-fill: #999999
  --socials-fill--nth2: #999999
  --socials-fill--nth3: #999999
  --socials-margin: 16px
  --socials-bg-color: #ffffff
  --socials-bg-color--nth2: #ffffff
  --socials-bg-color--nth3: #ffffff
  --socials-filter: unset

  --spoiler-border: 1px solid #c8c8c8
  --spoiler-border-radius: 8px
  --spoiler-bg-color: #ffffff
  --spoiler-border-bottom: 1px solid #c8c8c8
  --spoiler-border-top: 1px solid #c8c8c8
  --spoiler-wrapper-pt: 8px
  --spoiler-wrapper-pb: 8px
  --spoiler-title-font-family: inherit
  --spoiler-title-font-weight: 500
  --spoiler-title-color: inherit
  --spoiler-title-bg-color: inherit
  --spoiler-title-bg-image: none
  --spoiler-title-padding: 6px 14.5px 6px 16px
  --spoiler-title-font-size: 14px
  --spoiler-title-line-height: 1.35
  --spoiler-title-height: 30px
  --spoiler-title-border-radius: 0
  --spoiler-arrow-fill: #c8c8c8
  --spoiler-hidden-font-family: inherit
  --spoiler-hidden-border-top: 1px solid #c8c8c8
  --spoiler-hidden-border-left: none
  --spoiler-hidden-padding: 8px 16px
  --spoiler-hidden-line-height: 1.2
  --spoiler-hidden-font-size: 14px
  --spoiler-hidden-color: inherit

  --gallery-grid-margin: 0 -1px
  --gallery-col-padding: 0 1px
  --gallery-col-margin: 2px
  --gallery-image-border-radius: unset
  --gallery-pagination-margin: unset
  --gallery-pagination-bullet-size: 4px
  --gallery-pagination-bullet-margin: 0 4px
  --gallery-pagination-bullet-color: #dddddd
  --gallery-pagination-bullet-color--active: #000000

  width: 100%
  background: var(--page-bg-color)
  display: flex
  flex-direction: column
  font-family: var(--font-family)
  color: var(--color)

  .editor__component
    background-color: var(--page-bg-color)

  &__header

    &__text-wrapper
      padding: 32px 24px 0
      display: flex
      flex-direction: column
      margin: 0 auto
      max-width: 480px

      & .title
        text-align: center
        font-weight: 700

      & .text
        margin-bottom: 14px

  &__content
    flex: 1 1 auto
    display: flex
    flex-direction: column

  &__loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin: auto 0

  &__main
    display: flex
    flex-direction: column

  &__footer
    padding: 24px 0
    text-align: center
    font-weight: 400
    font-size: 14px
    line-height: 16px
    color: var(--footer-color)

    & a
      color: var(--footer-link-color)

  &__component
    width: 100%
    margin: 0 auto
    padding-left: 16px
    padding-right: 16px

//Common

.avatar__username,
.title,
.subtitle,
.text,
.button,
.button__label
  word-break: break-word

// HeaderEC

.background-wrapper
  width: 100%
  height: 128px
  background-color: var(--header-bg-color)
  background-image: var(--header-bg-image)
  background-position: center center
  background-size: cover
  background-repeat: no-repeat

  &--sm
    height: 104px

  &--lg
    height: 144px

.avatar
  width: 100%
  height: 100%
  object-fit: cover

  &-wrapper
    max-width: 360px
    padding: 0 24px
    margin: -48px auto 16px auto
    display: flex

    &--center
      flex-direction: column
      align-items: center

      .avatar__username
        margin: 8px 0 0 0
        text-align: center
        color: var(--avatar-color)
        font-weight: var(--avatar-font-weight)

        &-wrapper
          max-width: 100%

    &--center.avatar-wrapper--sm

      .avatar__username
        margin: 6px 0 0 0

        &-wrapper
          max-width: 100%

    &--center.avatar-wrapper--lg

      .avatar__username
        margin: 12px 0 0 0

        &-wrapper
          max-width: 100%

    &--right
      justify-content: flex-end

      & .avatar__username
        margin-left: 0
        margin-right: 24px

        &-wrapper
          order: -1

    &--sm
      margin-top: -36px

      .avatar__image-wrapper
        min-width: 72px
        max-width: 72px
        height: 72px

      .avatar__username
        margin-bottom: 0

        &-wrapper
          max-width: calc(100% - 72px)

    &--lg
      margin-top: -56px

      .avatar__image-wrapper
        min-width: 112px
        max-width: 112px
        height: 112px

      .avatar__username-wrapper
        max-width: calc(100% - 112px)

    &--photo-disabled
      margin-top: 16px

      .avatar__username
        margin: 0

        &-wrapper
          max-width: 100%
          margin: 0 auto

    &--cover-disabled
      margin-top: 48px

  &__image-wrapper
    min-width: 96px
    max-width: 96px
    height: 96px
    display: flex
    align-items: center
    justify-content: center
    border: 2px solid #FFF
    border-radius: 50%
    background-color: #DEDEDE
    overflow: hidden
    flex: 1 0 auto

  &__username
    color: var(--avatar-color)
    font-size: 14px
    margin: 0 0 0 24px
    max-width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    min-height: 24px

    &-wrapper
      display: flex
      align-items: flex-end
      max-width: calc(100% - 96px)

      &--disabled
        display: none

// TextEC

// Old alignment support

.title, .subtitle, .text

  &--center
    text-align: center

  &--right
    text-align: right

  &--justify
    text-align: justify

// New alignment

.alignment

  &-center
    text-align: center

  &-right
    text-align: right

  &-left
    text-align: center

  &-justify
    text-align: justify

.title
  font-family: var(--title-font-family)
  color: var(--title-color)
  font-size: var(--title-font-size)
  font-weight: var(--title-font-weight)
  line-height: var(--title-line-height)
  margin-bottom: var(--title-margin)

.text-wrapper
  padding-top: 8px
  min-height: 32px
  display: flex
  flex-direction: column

  & > :last-child
    margin-bottom: 14px

.text
  font-size: var(--text-font-size)
  line-height: var(--text-line-height)
  margin-bottom: var(--text-margin)
  font-weight: var(--text-font-weight)
  font-family: var(--text-font-family)

.subtitle
  font-family: var(--subtitle-font-family)
  color: var(--subtitle-color)
  font-size: var(--subtitle-font-size)
  font-weight: var(--subtitle-font-weight)
  line-height: var(--subtitle-line-height)
  margin-bottom: var(--subtitle-margin)

.link
  text-decoration: var(--link-text-decoration)
  color: var(--link-color)

  &:hover
    text-decoration: var(--link-text-decoration__hover)

// ButtonEC
.editor__component--clicked
  .button
    z-index: 0
.button
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  max-width: 480px
  padding: 8px
  min-height: var(--button-height)
  margin: 0 auto
  font-family: var(--button-font-family)
  font-weight: var(--button-font-weight)
  font-size: var(--button-font-size)
  line-height: var(--button-line-height)
  color: var(--button-color)
  border: var(--button-border)
  border-radius: var(--button-border-radius)
  transition: var(--button-transition)
  background: var(--button-bg-color)
  box-shadow: var(--button-box-shadow)
  opacity: var(--button-opacity)

  &:hover
    text-decoration: none
    color: var(--button-color__hover)
    background: var(--button-bg-color__hover)
    border: var(--button-border__hover)
    filter: var(--button-filter__hover)
    opacity: var(--button-opacity__hover)

  &:active
    color: var(--button-color__active)
    background: var(--button-bg-color__active)
    border: var(--button-border__active)
    filter: var(--button-filter__active)
    opacity: var(--button-opacity__active)

  &--secondary
    background: var(--button-bg-color--secondary)
    color: var(--button-color--secondary)
    min-height: var(--button-height--secondary)
    font-size: var(--button-font-size--secondary)
    line-height: var(--button-line-height--secondary)
    box-shadow: var(--button-box-shadow--secondary)
    border: var(--button-border--secondary)
    border-radius: var(--button-border-radius--secondary)
    -webkit-background-clip: var(--button-background-clip--secondary)
    -webkit-text-fill-color: var(--button-text-fill-color--secondary)

    &:hover
      color: var(--button-color--secondary__hover)
      background: var(--button-bg-color--secondary__hover)
      border: var(--button-border--secondary__hover)
      filter: var(--button-filter--secondary__hover)
      opacity: var(--button-opacity--secondary__hover)

    &:active
      color: var(--button-color--secondary__active)
      background: var(--button-bg-color--secondary__active)
      border: var(--button-border--secondary__active)
      filter: var(--button-filter--secondary__active)
      opacity: var(--button-opacity--secondary__active)

  &-wrapper
    display: flex
    flex-direction: column
    text-align: center
    padding-top: 12px
    padding-bottom: 12px

  &__label
    font-size: var(--button-desc-font-size)
    line-height: var(--button-desc-line-height)
    color: var(--button-desc-color)
    font-weight: var(--button-desc-font-weight)
    font-family: var(--button-desc-font-family)
    max-width: 480px
    margin: var(--button-desc-margin)
    display: block

// DividerEC

.divider
  border-bottom-width: 4px
  border-color: var(--separator-border-color)
  width: 100%

  &-wrapper
    display: flex
    align-items: center
    padding-top: 12px
    padding-bottom: 12px
    margin-bottom: 11px

  &--sm
    border-bottom-width: 2px

  &--lg
    border-bottom-width: 6px

  &--solid
    border-bottom-style: solid

  &--dashed
    border-bottom-style: dashed

  &--dotted
    border-bottom-style: dotted

  &--zigzag, &--wave
    border-bottom-style: solid
    border-top-width: 0
    border-left-width: 0
    border-right-width: 0
    opacity: var(--separator-opacity)

  &--zigzag
    border-image: var(--separator-border-image--zigzag)
    border-bottom-width: 7px

    &.divider--md
      border-bottom-width: 9px

    &.divider--lg
      border-bottom-width: 11px

  &--wave
    border-image: var(--separator-border-image--wave)
    border-bottom-width: 4px

    &.divider--md
      border-bottom-width: 6px

    &.divider--lg
      border-bottom-width: 8px

// VideoEC
.editor__component--clicked
  .video
    &-wrapper
      & iframe
        z-index: 0
.video

  &-wrapper
    margin-top: 24px
    margin-bottom: 24px
    display: flex
    height: 162px
    & div

      & div
        position: relative
        padding-bottom: 56.25%
        height: 0 !important

        & iframe
          z-index: -1
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%


// ContactsEC, SocialsEC

.contacts, .socials
  display: flex
  align-items: center
  justify-content: center
  width: 48px
  height: 48px

  &-wrapper
    display: flex
    align-items: center
    justify-content: center
    padding-top: 8px
    padding-bottom: 8px
    min-height: 80px
    flex-wrap: wrap

// ContactsEC

.contacts
  margin: 8px

// SocialsEC

.socials
  border-radius: var(--socials-border-radius)
  border: var(--socials-border)
  margin: var(--socials-margin)
  background-color: var(--socials-bg-color)
  width: var(--socials-size)
  height: var(--socials-size)

  &:nth-child(3n + 2)
    background-color: var(--socials-bg-color--nth2)

    .socials__icon > *
      fill: var(--socials-fill--nth2)

  &:nth-child(3n + 3)
    background-color: var(--socials-bg-color--nth3)

    .socials__icon > *
      fill: var(--socials-fill--nth3)

  &__icon
    width: var(--socials-icon-size)
    height: var(--socials-icon-size)
    filter: var(--socials-filter)

    & > *
      fill: var(--socials-fill)

// TimerEC

.timer
  color: var(--timer-color)
  font-size: var(--timer-font-size)
  line-height: var(--timer-line-height)
  font-weight: var(--timer-font-weight)
  font-family: var(--timer-font-family)
  text-align: center

  &-wrapper
    display: flex
    align-items: center
    justify-content: center
    padding-top: 8px
    padding-bottom: 8px
    min-height: 56px

  &--md
    font-weight: var(--timer-font-weight--md)
    font-size: var(--timer-font-size--md)
    line-height: var(--timer-line-height--md)

  &--lg
    font-weight: var(--timer-font-weight--lg)
    font-size: var(--timer-font-size--lg)
    line-height: var(--timer-line-height--lg)

// ImageEC

.image
  border-radius: var(--image-border-radius)
  object-fit: contain
  width: 100%

  &-wrapper
    padding-top: 8px
    padding-bottom: 8px

  &-container
    overflow: hidden
    display: flex
    align-items: center
    padding: 0
    width: 100%
    max-width: 128px
    margin: 0 auto

    &--left
      margin: 0 auto 0 0

    &--right
      margin: 0 0 0 auto

    &--justify
      margin: 0
      max-width: 100%

    &--clickable
      appearance: none
      background-color: transparent
      box-shadow: none
      border: none

      &:focus
        outline: none

// GalleryEC

.gallery
  display: flex
  flex-wrap: wrap

  &--grid
    margin: var(--gallery-grid-margin)

  & > *
    width: 100%

  &-wrapper
    padding-top: 16px
    padding-bottom: 16px
    min-height: 112px

  &__col
    display: flex
    padding: var(--gallery-col-padding)
    margin-bottom: var(--gallery-col-margin)

    &--1
      max-width: 100%
      height: 241.5px

    &--2
      max-width: 50%
      height: 120.75px

    &--3
      max-width: 33.3333%
      height: 80.5px

    &--4
      max-width: 25%
      height: 61px

  &__image
    width: 100%
    height: 100%
    object-fit: cover

    &-wrapper
      display: flex
      align-items: center
      justify-content: center
      overflow: hidden
      width: 100%
      border-radius: var(--gallery-image-border-radius)

    &-placeholder
      border-radius: var(--gallery-image-border-radius)

  // Swiper
  .swiper-wrapper, .swiper-container
    z-index: unset

  .swiper-container
    display: flex
    flex-direction: column

  .swiper-wrapper
    order: -1

  .swiper-pagination
    position: static
    display: flex
    align-items: center
    justify-content: center
    min-height: 20px
    margin-top: var(--gallery-pagination-margin)

  &__slide
    width: 240px
    height: 164px

    .gallery__image
      width: 100%

      &-wrapper
        height: 100%
        width: 100%

  &__pagination

    &__bullet
      display: inline-block
      width: var(--gallery-pagination-bullet-size)
      height: var(--gallery-pagination-bullet-size)
      border-radius: 50%
      background-color: var(--gallery-pagination-bullet-color)
      margin: var(--gallery-pagination-bullet-margin)

      &--active
        background-color: var(--gallery-pagination-bullet-color--active)

// AdEC

.commercial
  background-color: var(--commercial-bg-color)
  border: var(--commercial-border)
  border-radius: var(--commercial-border-radius)
  padding: 16px
  max-width: 480px
  margin: 0 auto

  & > *
    display: flex
    flex-direction: column
    max-width: 360px
    margin-left: auto
    margin-right: auto

  &__button-wrapper
    align-items: center
    text-align: center

    & > :last-child
      margin-bottom: 0

  &__timer-wrapper
    margin-bottom: 19px

  &__image-wrapper
    margin-bottom: 16px

    .image-container
      min-height: 48px
      max-width: 164px

      &--justify
        max-width: 100%

  &-wrapper
    min-height: 50px
    padding-top: 16px
    padding-bottom: 16px

// SpoilerEC
.editor__workspace--tab-1
  .editor__component--clicked
    .spoiler
      .collapse
        display: block
.spoiler
  border: var(--spoiler-border)
  border-radius: var(--spoiler-border-radius)
  border-bottom: var(--spoiler-border-bottom)
  border-top: var(--spoiler-border-top)
  background-color: var(--spoiler-bg-color)
  word-break: break-word
  min-height: 32px

  &-wrapper
    padding-top: var(--spoiler-wrapper-pt)
    padding-bottom: var(--spoiler-wrapper-pb)

  &__title
    font-family: var(--spoiler-title-font-family)
    font-weight: var(--spoiler-title-font-weight)
    color: var(--spoiler-title-color)
    background-color: var(--spoiler-title-bg-color)
    background-image: var(--spoiler-title-bg-image)
    line-height: var(--spoiler-title-line-height)
    font-size: var(--spoiler-title-font-size)
    cursor: pointer
    padding: var(--spoiler-title-padding)
    min-height: var(--spoiler-title-height)
    border-radius: var(--spoiler-title-border-radius)
    display: flex
    align-items: center
    justify-content: space-between

    &__arrow
      min-width: 12px
      margin-left: 10px

      & > *
        fill: var(--spoiler-arrow-fill)

  &__hidden
    font-family: var(--spoiler-hidden-font-family)
    color: var(--spoiler-hidden-color)
    line-height: var(--spoiler-hidden-line-height)
    padding: var(--spoiler-hidden-padding)
    border-top: var(--spoiler-hidden-border-top)
    border-left: var(--spoiler-hidden-border-left)
    font-size: var(--spoiler-hidden-font-size)

    & > :last-child
      margin-bottom: 0

//PerfluenceEC & HTMLBlockEC
.perfluence-block
  &-wrapper
    display: none

.perfluence-block, .html-block

  &-wrapper
    padding-top: 8px
    padding-bottom: 8px

  &-placeholder
    display: flex
    flex-direction: column
    align-items: center
    background-color: #fff
    color: #000
    border: 1px solid #ccc
    border-radius: 8px
    font-size: 1.5rem
    line-height: 1.5
    padding: 1rem

    &__text
      margin-bottom: 0.5rem

    &__image
      width: 120px
      height: auto
      max-width: 100%

//PerfluenceEC

.pfm-page .perfluence-block
  padding: 8px 16px
  font-family: 'Inter', sans-serif
  background-color: #ffffff
  margin: 0 -16px

  .gallery__pagination__bullet
    border: 1px solid var(--gallery-pagination-bullet-color--active)

  .swiper-container
    padding-bottom: 0
    display: flex
    flex-direction: column

    .swiper-wrapper
      order: -1
      min-height: auto

  .swiper-pagination
    bottom: 0
    position: static
    display: flex
    justify-content: center
    align-items: center
    padding: 5px 0

  .swiper-slide
    transition: opacity 0.4s
  //change
  .swiper-slide-prev, .swiper-slide-next
    opacity: .7

  & .commercial-wrapper
    padding-top: 8px
    padding-bottom: 8px

  & .commercial
    border: none
    color: #000
    background-color: #6abc50
    border-radius: 12px
    margin: 0 auto 16px auto

    &:last-child
      margin-bottom: 0

    & > *
      margin-left: auto
      margin-right: auto
      max-width: 360px

.pfm-page .perfluence-block .commercial

  & :last-child > :last-child
    margin-bottom: 0

  &__header-wrapper__component,
  &__content-wrapper,
  &__text-wrapper

    & .title, & h1,
    & .subtitle, & h2,
    & .text, & p
      font-family: inherit
      color: inherit
      margin-bottom: 18px

    & .title, & h1
      font-size: 20px
      line-height: 24px
      font-weight: 700

    & .subtitle, & h2
      font-size: 18px
      line-height: 21px
      font-weight: 700

    & .text, & p
      font-size: 14px
      line-height: 16px
      font-weight: 500

    & .link, & a:not(.button)
      text-decoration: underline
      color: inherit

      &:hover
        text-decoration: none

  &__header-wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    max-width: 360px
    margin-bottom: 16px

    &__component

      &:last-child
        margin-left: 16px
        margin-right: 16px

      & .image-container
        min-width: 64px
        max-width: 128px

        & .image
          border-radius: 8px

      & .title, & h1,
      & .subtitle, & h2,
      & .text, & p
        margin-bottom: 0

  &__promocode-wrapper
    text-align: center

    & .commercial__promocode
      margin-bottom: 32px
      word-break: break-word

      &__code
        display: flex
        align-items: center
        justify-content: center
        font-size: 20px
        line-height: 24px
        font-weight: 700
        min-height: 32px
        margin-bottom: 8px
        background-repeat: no-repeat
        background-position: center
        background-size: contain
        position: relative
      &__text
        font-size: 14px
        line-height: 16px
        font-weight: 500

  &__timer-wrapper
    text-align: center
    margin-bottom: 18px

    & .timer
      font-family: inherit
      color: inherit

    & .timer,
    & .timer.timer--sm
      font-size: 16px
      line-height: 19px
      font-weight: 400

    & .timer.timer--md,
    & .timer.timer--lg
      font-weight: 700

    & .timer.timer--md
      font-size: 18px
      line-height: 21px

    & .timer.timer--lg
      font-size: 24px
      line-height: 28px

  &__button-wrapper
    text-align: center
    margin-bottom: 9px

    & .button
      background-color: #fff
      color: #000
      border: none
      background-image: none
      font-size: 16px
      line-height: 19px
      font-weight: 500
      font-family: inherit
      margin-bottom: 0
      box-shadow: none
      min-height: 40px
      border-radius: 8px
      -webkit-background-clip: unset
      -webkit-text-fill-color: unset

      &:hover
        background-color: #fff
        color: #000
        opacity: 0.7

      &:active
        background-color: #fff
        color: #000
        opacity: 1
        filter: brightness(1.1)

      &__label
        font-family: inherit
        font-weight: 500
        font-size: 12px
        line-height: 15px
        color: #000
        margin-top: 4px

//INSTAGRAM

.pfm-page.pfm-page--instagram
  .gallery
    &__col
      &--1
        max-width: 100%
        height: 345px

      &--2
        max-width: 50%
        height: 172.5px

      &--3
        max-width: 33.3333%
        height: 115px

      &--4
        max-width: 25%
        height: 86.25px

    &__slide
      width: 212px
      height: 212px


//ATOMS

.block-switcher__option.block-switcher__option--atoms
  font-family: var(--font-family)

.pfm-page.pfm-page--atoms, .block-switcher__option.block-switcher__option--atoms
  --font-family: 'Inter', sans-serif
  --header-bg-color: #{$color-atoms-secondary}
  --header-bg-image: url("../../assets/cover-atoms.png")

  --avatar-color: #{$color-atoms}

  --footer-link-color: #{$color-atoms}

  --separator-border-color: #000
  --separator-opacity: 1

  --socials-border-radius: 8px
  --socials-border: 1px solid #{$color-atoms}
  --socials-bg-color: #{$color-atoms}
  --socials-bg-color--nth2: #{$color-atoms}
  --socials-bg-color--nth3: #{$color-atoms}
  --socials-fill: #fff
  --socials-fill--nth2: #fff
  --socials-fill--nth3: #fff

  --gallery-grid-margin: 0 -8px
  --gallery-col-padding: 0 8px
  --gallery-col-margin: 16px
  --gallery-image-border-radius: 8px
  --gallery-pagination-margin: 6px
  --gallery-pagination-bullet-size: 12px
  --gallery-pagination-bullet-color: #c4c4c4
  --gallery-pagination-bullet-color--active: #000

  --spoiler-border: none
  --spoiler-border-top: none
  --spoiler-border-radius: 0
  --spoiler-border-bottom: 1px solid #dadada
  --spoiler-title-padding: 6px 0
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.2
  --spoiler-title-color: #{$color-atoms}
  --spoiler-arrow-fill: #{$color-atoms}
  --spoiler-hidden-padding: 5px 0 11px
  --spoiler-hidden-border-top: none

  --commercial-bg-color: #FFD473

  --button-bg-color: #{$color-atoms}
  --button-border: 1px solid #{$color-atoms}
  --button-color: #fff
  --button-color__hover: #fff
  --button-bg-color__hover: #{$color-atoms-hover}
  --button-border__hover: #{$color-atoms-hover}
  --button-color__active: #fff
  --button-bg-color__active: #{$color-atoms-active}
  --button-border__active: #{$color-atoms-active}
  --button-bg-color--secondary: #fff
  --button-color--secondary: #{$color-atoms}
  --button-border--secondary: 1px solid #{$color-atoms}
  --button-height--secondary: 48px
  --button-font-size--secondary: 16px
  --button-line-height--secondary: 1.2
  --button-bg-color--secondary__hover: #fff
  --button-border--secondary__hover: 1px solid #{$color-atoms-hover}
  --button-color--secondary__hover: #{$color-atoms-hover}
  --button-bg-color--secondary__active: #fff
  --button-color--secondary__active: #{$color-atoms-active}
  --button-border--secondary__active: 1px solid #{$color-atoms-active}

  --link-color: #{$color-atoms}

  --timer-font-weight: 800
  --timer-font-weight--md: 800
  --timer-font-weight--lg: 800

  --image-border-radius: 8px

// /ATOMS

// TIFFANY

.pfm-page.pfm-page--tiffany, .block-switcher__option.block-switcher__option--tiffany
  --header-bg-color: #{$color-tiffany-secondary}
  --header-bg-image: url("../../assets/cover-tiffany.png")

  --avatar-color: #{$color-tiffany}
  --avatar-font-weight: 700

  --footer-link-color: #{$color-tiffany}

  --separator-border-color: #00A59B
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-tiffany.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-tiffany.svg") 8 repeat

  --socials-border-radius: 4px
  --socials-border: 1px solid #{$color-tiffany}
  --socials-bg-color: #{$color-tiffany}
  --socials-bg-color--nth2: #{$color-tiffany}
  --socials-bg-color--nth3: #{$color-tiffany}
  --socials-margin: 8px
  --socials-size: 32px
  --socials-icon-size: 16px
  --socials-fill: #fff
  --socials-fill--nth2: #fff
  --socials-fill--nth3: #fff

  --gallery-grid-margin: 0 -6px
  --gallery-col-padding: 0 6px
  --gallery-col-margin: 12px
  --gallery-image-border-radius: 4px
  --gallery-pagination-margin: 3px
  --gallery-pagination-bullet-size: 10px
  --gallery-pagination-bullet-color: #939393
  --gallery-pagination-bullet-color--active: #{$color-tiffany}

  --spoiler-border: none
  --spoiler-border-bottom: none
  --spoiler-border-top: none
  --spoiler-border-radius: 0
  --spoiler-bg-color: #fff
  --spoiler-wrapper-pt: 8px
  --spoiler-wrapper-pb: 8px
  --spoiler-title-color: #{$color-tiffany}
  --spoiler-title-padding: 0 0 5px
  --spoiler-arrow-fill: #{$color-tiffany}
  --spoiler-hidden-padding: 4px 6px
  --spoiler-hidden-border-top: none
  --spoiler-hidden-border-left: 2px solid #{$color-tiffany}

  --commercial-bg-color: #FFDECD

  --button-bg-color: #{$color-tiffany}
  --button-border: 2px solid #{$color-tiffany}
  --button-color: #fff
  --button-border-radius: 24px
  --button-color__hover: #fff
  --button-bg-color__hover: #{$color-tiffany-hover}
  --button-border__hover: #{$color-tiffany-hover}
  --button-color__active: #fff
  --button-bg-color__active: #{$color-tiffany-active}
  --button-border__active: #{$color-tiffany-active}
  --button-bg-color--secondary: #F4F4F4
  --button-border--secondary: 2px solid #{$color-tiffany}
  --button-border-radius--secondary: 24px
  --button-color--secondary: #{$color-tiffany}
  --button-height--secondary: 40px
  --button-border--secondary__hover: 2px solid #{$color-tiffany}
  --button-color--secondary__hover: #{$color-tiffany}
  --button-bg-color--secondary__hover: #fff
  --button-border--secondary__active: 2px solid #{$color-tiffany}
  --button-color--secondary__active: #{$color-tiffany}
  --button-bg-color--secondary__active: #E2E2E2

  --title-color: #{$color-tiffany}

  --subtitle-color: #{$color-tiffany}
  --subtitle-font-size: 20px
  --subtitle-line-height: 1.15

  --text-font-size: 16px
  --text-line-height: 1.19

  --link-color: #{$color-tiffany}
  --link-text-decoration: underline
  --link-text-decoration__hover: none

  --timer-font-weight: 900
  --timer-font-size--md: 20px
  --timer-line-height--md: 1.15


  --image-border-radius: 4px


// /TIFFANY

// GRAPHITE

.block-switcher__option.block-switcher__option--graphite
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--graphite, .block-switcher__option.block-switcher__option--graphite
  --color: #fff
  --font-family: 'Droid Sans', sans-serif

  --page-bg-color: #{$color-graphite}

  --commercial-bg-color: #444444

  --button-bg-color: #{$color-graphite-secondary}
  --button-border: 1px solid #{$color-graphite-secondary}
  --button-color: #{$color-graphite}
  --button-font-family: 'Rubik', sans-serif
  --button-border-radius: 3px
  --button-height: 40px
  --button-color__hover: #{$color-graphite}
  --button-bg-color__hover: #{$color-graphite-hover}
  --button-border__hover: 1px solid #{$color-graphite-hover}
  --button-color__active: #{$color-graphite}
  --button-bg-color__active: #{$color-graphite-active}
  --button-border__active: #{$color-graphite-active}
  --button-bg-color--secondary: #232323
  --button-border--secondary: 1px solid #232323
  --button-height--secondary: 40px
  --button-border-radius--secondary: 8px
  --button-color--secondary: #{$color-graphite-secondary}
  --button-font-size--secondary: 16px
  --button-line-height--secondary: 1.19
  --button-color--secondary__hover: #{$color-graphite-secondary}
  --button-bg-color--secondary__hover: #{$color-graphite-secondary-btn-hover}
  --button-border--secondary__hover: 1px solid #{$color-graphite-secondary-btn-hover}
  --button-color--secondary__active: #{$color-graphite-secondary}
  --button-bg-color--secondary__active: #{$color-graphite-secondary-btn-active}
  --button-border--secondary__active: 1px solid #{$color-graphite-secondary-btn-active}
  --button-desc-color: #666
  --button-desc-font-family: 'Roboto', sans-serif
  --button-desc-margin: 8px auto 0

  --title-font-family: 'Rubik', sans-serif

  --subtitle-font-family: 'Rubik', sans-serif

  --link-color: #fff
  --link-text-decoration: underline

  --link-text-decoration__hover: none

  --timer-font-size: 14px
  --timer-line-height: 1.22
  --timer-font-family: 'Rubik', sans-serif

  --timer-font-weight--md: 700
  --timer-font-size--md: 18px
  --timer-line-height--md: 1.17

  --timer-font-weight--lg: 700
  --timer-font-size--lg: 24px
  --timer-line-height--lg: 1.2

  --image-border-radius: 3px

  --header-bg-color: #{$color-graphite}
  --header-bg-image: url("../../assets/cover-graphite.png")

  --avatar-color: #fff

  --separator-border-color: #5F5F5F
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-graphite.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-graphite.svg") 8 repeat

  --footer-color: #939393
  --footer-link-color: #fff

  --socials-border-radius: 3px
  --socials-border: 1px solid #505050
  --socials-bg-color: #505050
  --socials-bg-color--nth2: #505050
  --socials-bg-color--nth3: #505050
  --socials-fill: #fff
  --socials-fill--nth2: #fff
  --socials-fill--nth3: #fff

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 3px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #505050
  --gallery-pagination-bullet-color--active: #{$color-graphite-secondary}

  --spoiler-border: none
  --spoiler-border-bottom: none
  --spoiler-border-top: none
  --spoiler-border-radius: 0
  --spoiler-bg-color: transparent
  --spoiler-title-height: 32px
  --spoiler-title-border-radius: 4px
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.2
  --spoiler-title-color: #{$color-graphite-secondary}
  --spoiler-title-bg-color: #232323
  --spoiler-arrow-fill: #{$color-graphite-secondary}
  --spoiler-hidden-color: #999
  --spoiler-hidden-border-top: none

  .editor__component
    background-color: var(--page-bg-color)

// /GRAPHITE

// CLOUDS

.block-switcher__option.block-switcher__option--clouds
  font-family: var(--font-family)

.pfm-page.pfm-page--clouds, .block-switcher__option.block-switcher__option--clouds
  --font-family: 'Open Sans', sans-serif
  --page-bg-color: #{$color-clouds}

  --header-bg-color: #{$color-clouds-secondary}
  --header-bg-image: url("../../assets/cover-clouds.png")

  --avatar-color: #{$color-clouds-secondary}

  --separator-border-color: #FFFFFF
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-clouds.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-clouds.svg") 8 repeat

  --footer-link-color: #{$color-clouds-secondary}

  --socials-border-radius: 12px
  --socials-border: 1px solid #{$color-clouds-secondary}
  --socials-bg-color: #{$color-clouds-secondary}
  --socials-bg-color--nth2: #{$color-clouds-secondary}
  --socials-bg-color--nth3: #{$color-clouds-secondary}
  --socials-margin: 6px
  --socials-fill: #fff
  --socials-fill--nth2: #fff
  --socials-fill--nth3: #fff

  --gallery-grid-margin: 0 -6px
  --gallery-col-padding: 0 6px
  --gallery-col-margin: 12px
  --gallery-image-border-radius: 12px
  --gallery-pagination-margin: 6px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #FFFFFF
  --gallery-pagination-bullet-color--active: #{$color-clouds-secondary}

  --spoiler-border: none
  --spoiler-border-bottom: none
  --spoiler-border-top: none
  --spoiler-border-radius: 12px
  --spoiler-bg-color: #b4cff8
  --spoiler-title-color: #{$color-blue}
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.2
  --spoiler-title-padding: 6px 14px 6px 12px
  --spoiler-arrow-fill: #{$color-blue}
  --spoiler-hidden-padding: 6px 12px
  --spoiler-hidden-border-top: none

  --commercial-bg-color: #ffffff

  --button-bg-color: #{$color-clouds-secondary}
  --button-border: 2px solid #{$color-clouds-secondary}
  --button-border-radius: 8px
  --button-color: #fff
  --button-font-weight: 600
  --button-color__hover: #fff
  --button-bg-color__hover: #{$color-clouds-secondary-hover}
  --button-border__hover: 2px solid#{$color-clouds-secondary-hover}
  --button-color__active: #fff
  --button-bg-color__active: #{$color-clouds-secondary-active}
  --button-border__active: #{$color-clouds-secondary-active}
  --button-border--secondary: 2px solid #{$color-clouds-secondary}
  --button-border-radius--secondary: 12px
  --button-bg-color--secondary: transparent
  --button-color--secondary: #{$color-clouds-secondary}
  --button-height--secondary: 48px
  --button-font-size--secondary: 16px
  --button-line-height--secondary: 1.2
  --button-bg-color--secondary__hover: transparent
  --button-border--secondary__hover: 2px solid #{$color-clouds-secondary-hover}
  --button-color--secondary__hover: #{$color-clouds-secondary-hover}
  --button-color--secondary__active: #{$color-clouds-secondary-active}
  --button-border--secondary__active: 2px solid #{$color-clouds-secondary-active}
  --button-bg-color--secondary__active: transparent
  --button-desc-color: #222
  --button-desc-font-size: 16px
  --button-desc-line-height: 1.25
  --button-desc-margin: 4px auto 0

  --title-font-size: 32px
  --title-line-height: 1.4
  --title-font-weight: 700
  --title-margin: 10px

  --subtitle-font-size: 26px
  --subtitle-line-height: 1.35
  --subtitle-margin: 10px

  --text-font-size: 16px
  --text-line-height: 1.4
  --text-margin: 10px

  --link-color: #{$color-clouds-secondary}

  --timer-font-size: 16px
  --timer-line-height: 1.4
  --timer-font-weight: 600
  --timer-color: #191784
  --timer-font-weight--md: 600
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.4
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.4

  --image-border-radius: 12px

  .editor__component
    background-color: var(--page-bg-color)
// /CLOUDS

// AUTUMN

.block-switcher__option.block-switcher__option--autumn
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--autumn, .block-switcher__option.block-switcher__option--autumn
  --color: #{$color-autumn-text}
  --font-family: 'PT Serif', sans-serif
  --page-bg-color: #{$color-autumn}

  --header-bg-color: #{$color-autumn-secondary}
  --header-bg-image: url("../../assets/cover-autumn.png")

  --avatar-color: #6271FF

  --footer-link-color: #6271FF

  --separator-border-color: #{$color-autumn-secondary}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-autumn.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-autumn.svg") 8 repeat

  --socials-border-radius: 24px
  --socials-border: 2px solid #{$color-autumn-secondary}
  --socials-bg-color: transparent
  --socials-bg-color--nth2: transparent
  --socials-bg-color--nth3: transparent
  --socials-margin: 8px
  --socials-fill: #{$color-autumn-secondary}
  --socials-fill--nth2: #{$color-autumn-secondary}
  --socials-fill--nth3: #{$color-autumn-secondary}

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 12px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #A2A2A2
  --gallery-pagination-bullet-color--active: #{$color-autumn-secondary}

  --spoiler-border: none
  --spoiler-border-bottom: none
  --spoiler-border-top: 2px solid #F8BB9E
  --spoiler-border-radius: 0
  --spoiler-bg-color: transparent
  --spoiler-title-padding: 2px 2px 2px 0
  --spoiler-title-font-size: 18px
  --spoiler-title-line-height: 1.4
  --spoiler-arrow-fill: #{$color-autumn-text}
  --spoiler-hidden-padding: 2px 0
  --spoiler-hidden-border-top: none
  --spoiler-hidden-font-size: 16px
  --spoiler-hidden-line-height: 1.35

  --commercial-bg-color: #8EDDEF

  --button-bg-color: #{$color-autumn-secondary}
  --button-border: 2px solid #{$color-autumn-secondary}
  --button-color: #fff
  --button-border-radius: 24px
  --button-color__hover: #fff
  --button-bg-color__hover: #{$color-autumn-secondary-hover}
  --button-border__hover: #{$color-autumn-secondary-hover}
  --button-color__active: #fff
  --button-bg-color__active: #{$color-autumn-secondary-active}
  --button-border__active: #{$color-autumn-secondary-active}
  --button-bg-color--secondary: transparent
  --button-color--secondary: #{$color-autumn-secondary}
  --button-height--secondary: 48px
  --button-font-size--secondary: 16px
  --button-line-height--secondary: 1.2
  --button-border--secondary: 2px solid #{$color-autumn-secondary}
  --button-border-radius--secondary: 24px
  --button-color--secondary__hover: #{$color-autumn-secondary-hover}
  --button-bg-color--secondary__hover: transparent
  --button-border--secondary__hover: 2px solid #{$color-autumn-secondary-hover}
  --button-color--secondary__active: #{$color-autumn-secondary-active}
  --button-bg-color--secondary__active: transparent
  --button-border--secondary__active: 2px solid #{$color-autumn-secondary-active}
  --button-desc-color: #{$color-autumn-text}
  --button-desc-font-size: 16px
  --button-desc-line-height: 1.25
  --button-desc-margin: 4px auto 0

  --title-font-size: 32px
  --title-line-height: 1.3
  --title-margin: 6px

  --subtitle-font-size: 26px
  --subtitle-line-height: 1.31
  --subtitle-margin: 12px

  --text-font-size: 16px
  --text-line-height: 1.32
  --text-margin: 12px

  --link-color: #6271FF

  --timer-font-size: 16px
  --timer-line-height: 1.32
  --timer-color: #{$color-autumn-text}
  --timer-font-weight: 400
  --timer-font-weight--md: 400
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.35
  --timer-font-weight--lg: 400
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.32

  --image-border-radius: 12px

  .editor__component
    background-color: var(--page-bg-color)
// /AUTUMN

// PINK

.block-switcher__option.block-switcher__option--pink
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--pink, .block-switcher__option.block-switcher__option--pink
  --font-family: 'Lato', sans-serif
  --color: #{$color-pink-text}
  --header-bg-image: url("../../assets/cover-pink.png"), #{$gradient-pink}

  --avatar-color: #{$color-pink}

  --footer-link-color: #{$color-pink}

  --separator-border-color: #{$color-pink}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-pink.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-pink.svg") 8 repeat

  --socials-border: none
  --socials-border-radius: 0
  --socials-bg-color: transparent
  --socials-bg-color--nth2: transparent
  --socials-bg-color--nth3: transparent
  --socials-margin: 16px
  --socials-icon-size: 30px
  --socials-fill: #{$color-pink-dark}
  --socials-fill--nth2: #{$color-pink-dark}
  --socials-fill--nth3: #{$color-pink-dark}

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 8px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #ABA7A7
  --gallery-pagination-bullet-color--active: #D0173E

  --spoiler-border: none
  --spoiler-border-bottom: none
  --spoiler-border-top: none
  --spoiler-border-radius: 0
  --spoiler-title-font-weight: 700
  --spoiler-title-bg-color: #fff
  --spoiler-title-bg-image: #{$gradient-pink}
  --spoiler-title-font-size: 18px
  --spoiler-title-line-height: 1.23
  --spoiler-title-padding: 6px 2px 6px 0
  --spoiler-arrow-fill: #{$color-pink}
  --spoiler-hidden-padding: 2px 0 8px
  --spoiler-hidden-border-top: none
  --spoiler-hidden-font-size: 16px
  --spoiler-hidden-line-height: 1.2

  --commercial-bg-color: #fff
  --commercial-border: 1px dashed #{$color-pink}
  --commercial-border-radius: 8px

  --button-transition: 0.2s ease-in-out
  --button-font-weight: 700
  --button-bg-color: #{$gradient-pink}
  --button-color: #fff
  --button-border-radius: 8px
  --button-height: 50px
  --button-border: none
  --button-color__hover: #fff
  --button-opacity__hover: 0.7
  --button-bg-color__hover: #{$gradient-pink}
  --button-border__hover: none
  --button-color__active: #fff
  --button-opacity__active: 0.9
  --button-bg-color__active: #{$gradient-pink}
  --button-border__active: none
  --button-background-clip--secondary: text
  --button-text-fill-color--secondary: #{$color-pink}
  --button-bg-color--secondary: #fff
  --button-border--secondary: 2px solid #{$color-pink}
  --button-height--secondary: 50px
  --button-font-size--secondary: 16px
  --button-line-height--secondary: 1.2
  --button-border--secondary__hover: 2px solid #{$color-pink}
  --button-bg-color--secondary__hover: #fff
  --button-opacity--secondary__hover: 0.7
  --button-border--secondary__active: 2px solid #{$color-pink}
  --button-bg-color--secondary__active: #fff
  --button-opacity--secondary__active: 0.9
  --button-desc-color: #ABA7A7
  --button-desc-margin: 8px auto 0

  --title-color: #{$color-pink-dark}
  --title-font-size: 26px
  --title-line-height: 1.2
  --title-font-weight: 700
  --title-margin: 16px

  --subtitle-font-size: 20px
  --subtitle-line-height: 1.2
  --subtitle-margin: 8px
  --subtitle-font-weight: 700
  --subtitle-color: #{$color-pink-dark}

  --text-font-size: 16px
  --text-line-height: 1.2

  --link-color: #{$color-pink}

  --timer-font-size: 16px
  --timer-line-height: 1.2
  --timer-color: #{$color-pink-dark}
  --timer-font-weight: 700
  --timer-font-weight--md: 700
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.23
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.2

  --image-border-radius: 8px

  .spoiler
    &__title
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

// /PINK

// ECO

.block-switcher__option.block-switcher__option--eco
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--eco, .block-switcher__option.block-switcher__option--eco
  --font-family: 'Cera Pro', sans-serif
  --color: #585760

  --header-bg-image: url("../../assets/cover-eco.png")
  --header-bg-color: #{$color-eco}

  --avatar-color: #{$color-eco}
  --avatar-font-weight: 700

  --footer-link-color: #{$color-eco}

  --separator-border-color: #{$color-eco}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-eco.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-eco.svg") 8 repeat

  --socials-border: none
  --socials-border-radius: 24px
  --socials-bg-color: #F8F6F7
  --socials-bg-color--nth2: #F8F6F7
  --socials-bg-color--nth3: #F8F6F7
  --socials-margin: 6px
  --socials-fill: #{$color-eco}
  --socials-fill--nth2: #{$color-eco}
  --socials-fill--nth3: #{$color-eco}

  --gallery-grid-margin: 0 -8px
  --gallery-col-padding: 0 8px
  --gallery-col-margin: 16px
  --gallery-image-border-radius: 12px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #B1B1B3
  --gallery-pagination-bullet-margin: 0 5px
  --gallery-pagination-bullet-color--active: #{$color-eco}

  --spoiler-border: none
  --spoiler-border-top: none
  --spoiler-border-bottom: 1px solid #{$color-eco-dark}
  --spoiler-border-radius: 0
  --spoiler-title-font-weight: 400
  --spoiler-title-color: #{$color-eco-dark}
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.25
  --spoiler-title-padding: 5px 2px 6px 0
  --spoiler-arrow-fill: #{$color-eco-dark}
  --spoiler-hidden-padding: 4px 0 12px
  --spoiler-hidden-border-top: none

  --commercial-bg-color: #FFFFFF
  --commercial-border: 1px solid #{$color-eco}
  --commercial-border-radius: 4px

  --button-font-weight: 700
  --button-font-size: 18px
  --button-line-height: 1.3
  --button-bg-color: #{$color-eco}
  --button-color: #fff
  --button-border-radius: 30px
  --button-height: 50px
  --button-border: none
  --button-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
  --button-color__hover: #fff
  --button-bg-color__hover: #{$color-eco-hover}
  --button-border__hover: none
  --button-color__active: #fff
  --button-bg-color__active: #{$color-eco-active}
  --button-border__active: none
  --button-bg-color--secondary: #{$color-eco-secondary}
  --button-border-radius--secondary: 24px
  --button-height--secondary: 50px
  --button-font-size--secondary: 18px
  --button-line-height--secondary: 1.3
  --button-color--secondary: #{$color-eco-dark}
  --button-border--secondary: none
  --button-box-shadow--secondary: 0 4px 8px rgb(0 0 0 / 20%)
  --button-color--secondary__hover: #{$color-eco-dark}
  --button-bg-color--secondary__hover: #{$color-eco-secondary-hover}
  --button-border--secondary__hover: none
  --button-color--secondary__active: #{$color-eco-dark}
  --button-bg-color--secondary__active: #{$color-eco-secondary-active}
  --button-border--secondary__active: none
  --button-desc-color: #ABA7A7
  --button-desc-line-height: 1.3
  --button-desc-margin: 8px auto 0

  --title-font-size: 26px
  --title-line-height: 1.3
  --title-margin: 16px
  --title-color: #{$color-eco-dark}
  --title-font-weight: 700

  --subtitle-font-size: 20px
  --subtitle-line-height: 1.25
  --subtitle-margin: 8px
  --subtitle-color: #{$color-eco-dark}
  --subtitle-font-weight: 700

  --text-font-size: 16px
  --text-line-height: 1.25
  --text-font-weight: 500

  --link-color: #{$color-eco}

  --timer-font-size: 16px
  --timer-line-height: 1.25
  --timer-color: #{$color-eco}
  --timer-font-weight: 700
  --timer-font-weight--md: 700
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.25
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.25

  --image-border-radius: 12px

// /ECO

// MOSAIC
.block-switcher__option.block-switcher__option--mosaic
  font-family: var(--font-family)
  color: var(--color)

.pfm-page.pfm-page--mosaic, .block-switcher__option.block-switcher__option--mosaic
  --font-family: 'Manrope', sans-serif
  --color: #{$color-mosaic-text}

  --page-bg-color: #{$color-mosaic-secondary}

  --header-bg-image: url("../../assets/cover-mosaic.png")
  --header-bg-color: #{$color-mosaic}

  --avatar-font-weight: 600
  --avatar-color: #{$color-mosaic}

  --footer-link-color: #{$color-mosaic}

  --separator-border-color: #{$color-mosaic}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-mosaic.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-mosaic.svg") 8 repeat

  --socials-border: none
  --socials-border-radius: 6px
  --socials-bg-color: #DAF8EA
  --socials-bg-color--nth2: #D5F5FB
  --socials-bg-color--nth3: #FFEFD6
  --socials-fill: #62B793
  --socials-fill--nth2: #0CC3E7
  --socials-fill--nth3: #FFAE33
  --socials-margin: 13px
  --socials-size: 54px

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 6px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #{$color-mosaic-text}
  --gallery-pagination-bullet-margin: 0 5px
  --gallery-pagination-bullet-color--active: #{$color-mosaic}

  --spoiler-border: none
  --spoiler-border-top: none
  --spoiler-border-bottom: 1px solid #{$color-mosaic-text}
  --spoiler-border-radius: 0
  --spoiler-bg-color: transparent
  --spoiler-title-font-weight: 400
  --spoiler-title-color: #{$color-mosaic-dark}
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.4
  --spoiler-title-padding: 6px 2px 6px 0
  --spoiler-arrow-fill: #{$color-mosaic-text}
  --spoiler-hidden-line-height: 1.4
  --spoiler-hidden-padding: 0 0 10px
  --spoiler-hidden-border-top: none

  --commercial-bg-color: #FFFFFF
  --commercial-border: 1px solid #{$color-mosaic}
  --commercial-border-radius: 6px

  --button-font-weight: 700
  --button-font-size: 18px
  --button-line-height: 1.4
  --button-bg-color: #{$color-mosaic}
  --button-color: #fff
  --button-border-radius: 6px
  --button-height: 50px
  --button-border: 2px solid #{$color-mosaic}
  --button-color__hover: #fff
  --button-bg-color__hover: #{$color-mosaic-hover}
  --button-border__hover: #{$color-mosaic-hover}
  --button-color__active: #fff
  --button-bg-color__active: #{$color-mosaic-active}
  --button-border__active: #{$color-mosaic-active}
  --button-bg-color--secondary: #{$color-mosaic-secondary}
  --button-height--secondary: 50px
  --button-border-radius--secondary: 6px
  --button-font-size--secondary: 18px
  --button-line-height--secondary: 1.4
  --button-color--secondary: #{$color-mosaic-dark}
  --button-border--secondary: 2px solid #{$color-mosaic}
  --button-color--secondary__hover: #{$color-mosaic-dark}
  --button-bg-color--secondary__hover: #fff
  --button-border--secondary__hover: 2px solid #{$color-mosaic-hover}
  --button-color--secondary__active: #{$color-mosaic-dark}
  --button-bg-color--secondary__active: #{$color-mosaic-secondary-active}
  --button-border--secondary__active: 2px solid #{$color-mosaic-active}
  --button-desc-color: #{$color-mosaic-text}
  --button-desc-line-height: 1.4
  --button-desc-margin: 8px auto 0

  --title-font-size: 32px
  --title-line-height: 1.4
  --title-margin: 12px
  --title-font-weight: 700
  --title-color: #{$color-mosaic-dark}

  --subtitle-font-size: 24px
  --subtitle-line-height: 1.4
  --subtitle-margin: 2px
  --subtitle-font-weight: 700
  --subtitle-color: #{$color-mosaic-dark}

  --text-font-size: 18px
  --text-line-height: 1.4
  --text-margin: 12px

  --link-color: #{$color-mosaic}

  --timer-font-size: 16px
  --timer-line-height: 1.35
  --timer-color: #{$color-mosaic}
  --timer-font-weight: 700
  --timer-font-weight--md: 700
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.4
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.4

  --image-border-radius: 6px

  .editor__component
    background-color: var(--page-bg-color)

// /MOSAIC

// AURORA

.block-switcher__option.block-switcher__option--aurora
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--aurora, .block-switcher__option.block-switcher__option--aurora
  --font-family: 'Montserrat', sans-serif
  --color: #{$color-aurora-text}

  --page-bg-color: #{$color-aurora}

  --header-bg-image: url("../../assets/cover-aurora.png"), #{$gradient-aurora}
  --header-bg-color: #{$color-aurora-secondary}

  --avatar-color: #{$color-aurora-text}
  --avatar-font-weight: 600

  --footer-link-color: #{$color-aurora-secondary}

  --separator-border-color: #{$color-aurora-secondary}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-aurora.svg") 17 repeat

  --socials-border: none
  --socials-border-radius: 24px
  --socials-bg-color: #8c919c
  --socials-bg-color--nth2: #8c919c
  --socials-bg-color--nth3: #8c919c
  --socials-margin: 6px
  --socials-filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25))
  --socials-fill: #{$color-aurora-text}
  --socials-fill--nth2: #{$color-aurora-text}
  --socials-fill--nth3: #{$color-aurora-text}

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 6px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #{$color-aurora-text}
  --gallery-pagination-bullet-margin: 0 5px
  --gallery-pagination-bullet-color--active: #{$color-aurora-secondary}

  --spoiler-border: none
  --spoiler-border-top: none
  --spoiler-border-bottom: 1px solid #3D3D3D
  --spoiler-border-radius: 0
  --spoiler-bg-color: transparent
  --spoiler-title-font-weight: 400
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.25
  --spoiler-title-padding: 7px 2px 5px 0
  --spoiler-arrow-fill: #E5E9EA
  --spoiler-hidden-line-height: 1.2
  --spoiler-hidden-padding: 5px 0 9px
  --spoiler-hidden-border-top: none

  --commercial-bg-color: #8C919C
  --commercial-border: 1px solid #{$color-aurora-text}
  --commercial-border-radius: 6px

  --button-font-weight: 700
  --button-font-size: 18px
  --button-line-height: 1.25
  --button-bg-color: #{$gradient-aurora}
  --button-color: #{$color-aurora-text}
  --button-border-radius: 6px
  --button-height: 50px
  --button-border: none
  --button-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  --button-bg-color__hover: #{$gradient-aurora}
  --button-color__hover: #{$color-aurora-text}
  --button-filter__hover: brightness(1.1)
  --button-border__hover: none
  --button-color__active: #{$color-aurora-text}
  --button-filter__active: brightness(0.9)
  --button-bg-color__active: #{$gradient-aurora}
  --button-border__active: none
  --button-box-shadow--secondary: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 4px 4px rgba(255, 255, 255, 0.2)
  --button-bg-color--secondary: #8c919c
  --button-height--secondary: 50px
  --button-font-size--secondary: 18px
  --button-line-height--secondary: 1.25
  --button-color--secondary: #{$color-aurora-text}
  --button-border--secondary: none
  --button-filter--secondary__hover: none
  --button-color--secondary__hover: #{$color-aurora-text}
  --button-bg-color--secondary__hover: #9A9DA3
  --button-border--secondary__hover: none
  --button-filter--secondary__active: none
  --button-color--secondary__active: #{$color-aurora-text}
  --button-bg-color--secondary__active: #787C84
  --button-border--secondary__active: none
  --button-desc-font-weight: 500
  --button-desc-color: #DDDFE2
  --button-desc-line-height: 1.25
  --button-desc-margin: 8px auto 0

  --title-font-size: 32px
  --title-line-height: 1.25
  --title-font-weight: 700
  --title-margin: 12px

  --subtitle-font-size: 24px
  --subtitle-line-height: 1.25
  --subtitle-font-weight: 700
  --subtitle-margin: 9px

  --text-font-weight: 500
  --text-font-size: 18px
  --text-line-height: 1.25
  --text-margin: 9px

  --link-color: #{$color-aurora-secondary}

  --timer-font-weight: 700
  --timer-font-size: 16px
  --timer-line-height: 1.25
  --timer-color: #{$color-aurora-text}
  --timer-font-weight--md: 700
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.25
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.25

  --image-border-radius: 6px

  .editor__component
    background-color: var(--page-bg-color)

// /AURORA


// MINIMAL

.block-switcher__option.block-switcher__option--minimal
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--minimal, .block-switcher__option.block-switcher__option--minimal
  --color: #{$color-minimal-text}
  --font-family: 'Noto Sans', sans-serif

  --header-bg-image: url("../../assets/cover-minimal.png")
  --header-bg-color: #{$color-minimal}

  --avatar-color: #{$color-minimal}
  --avatar-font-weight: 700

  --footer-color: #{$color-minimal-text}
  --footer-link-color: #{$color-minimal}

  --separator-border-color: #{$color-minimal}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-minimal.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-minimal.svg") 8 repeat

  --socials-border: none
  --socials-border-radius: 0
  --socials-bg-color: #{$color-minimal}
  --socials-bg-color--nth2: #{$color-minimal}
  --socials-bg-color--nth3: #{$color-minimal}
  --socials-margin: 16px
  --socials-fill: #F2F2F2
  --socials-fill--nth2: #F2F2F2
  --socials-fill--nth3: #F2F2F2

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 0
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #{$color-minimal-text}
  --gallery-pagination-bullet-margin: 0 5px
  --gallery-pagination-bullet-color--active: #{$color-minimal}

  --spoiler-border: none
  --spoiler-border-top: none
  --spoiler-border-radius: 0
  --spoiler-border-bottom: 2px solid #{$color-minimal}
  --spoiler-bg-color: transparent
  --spoiler-wrapper-pt: 2px
  --spoiler-wrapper-pb: 8px
  --spoiler-title-font-weight: 400
  --spoiler-title-color: #{$color-minimal}
  --spoiler-title-font-size: 18px
  --spoiler-title-line-height: 1.4
  --spoiler-title-padding: 0 2px 0 0
  --spoiler-arrow-fill: #{$color-minimal}
  --spoiler-hidden-font-size: 16px
  --spoiler-hidden-line-height: 1.4
  --spoiler-hidden-padding: 0 0 8px
  --spoiler-hidden-border-top: none

  --commercial-bg-color: #ffffff
  --commercial-border: 1px solid #{$color-minimal}
  --commercial-border-radius: 0

  --button-font-weight: 700
  --button-font-size: 18px
  --button-line-height: 1.4
  --button-bg-color: #{$color-minimal}
  --button-color: #F5F5FB
  --button-border-radius: 0
  --button-height: 50px
  --button-border: 2px solid #{$color-minimal}
  --button-color__hover: #F5F5FB
  --button-bg-color__hover: #{$color-minimal-hover}
  --button-border__hover: #{$color-minimal-hover}
  --button-color__active: #F5F5FB
  --button-bg-color__active: #{$color-minimal-active}
  --button-border__active: #{$color-minimal-active}
  --button-bg-color--secondary: #fff
  --button-height--secondary: 50px
  --button-font-size--secondary: 18px
  --button-line-height--secondary: 1.4
  --button-color--secondary: #{$color-minimal}
  --button-border--secondary: 2px solid #{$color-minimal}
  --button-border-radius--secondary: 0
  --button-color--secondary__hover: #{$color-minimal-hover}
  --button-bg-color--secondary__hover: #fff
  --button-border--secondary__hover: 2px solid #{$color-minimal-hover}
  --button-color--secondary__active: #{$color-minimal-active}
  --button-bg-color--secondary__active: #fff
  --button-border--secondary__active: 2px solid #{$color-minimal-active}
  --button-desc-font-weight: 500
  --button-desc-color: #{$color-minimal-text}
  --button-desc-line-height: 1.4
  --button-desc-margin: 8px auto 0

  --title-font-size: 24px
  --title-line-height: 1.4
  --title-margin: 16px
  --title-font-weight: 700
  --title-color: #{$color-minimal}

  --subtitle-font-size: 18px
  --subtitle-line-height: 1.4
  --subtitle-margin: 9px
  --subtitle-font-weight: 700
  --subtitle-color: #{$color-minimal}

  --text-font-weight: 500
  --text-font-size: 14px
  --text-line-height: 1.4
  --text-margin: 9px

  --link-color: #{$color-minimal}
  --link-text-decoration: underline
  --link-text-decoration__hover: none

  --timer-font-weight: 700
  --timer-font-size: 16px
  --timer-line-height: 1.4
  --timer-color: #{$color-minimal}
  --timer-font-weight--md: 700
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.4
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.4

  --image-border-radius: 0

// /MINIMAL

// PASTEL

.block-switcher__option.block-switcher__option--pastel
  color: var(--color)
  font-family: var(--font-family)

.pfm-page.pfm-page--pastel, .block-switcher__option.block-switcher__option--pastel
  --color: #{$color-pastel-text}
  --font-family: 'Source Sans Pro', sans-serif

  --page-bg-color: #{$color-pastel-bg}

  --header-bg-image: url("../../assets/cover-pastel.png")
  --header-bg-color: #{$color-pastel-secondary}

  --avatar-color: #{$color-pastel}
  --avatar-font-weight: 400

  --footer-color: #{$color-pastel-text}
  --footer-link-color: #{$color-pastel-secondary}

  --separator-border-color: #{$color-pastel}
  --separator-opacity: 1
  --separator-border-image--zigzag: url("../../assets/divider-border-zigzag-pastel.svg") 17 repeat
  --separator-border-image--wave: url("../../assets/divider-border-wave-pastel.svg") 8 repeat

  --socials-border: none
  --socials-border-radius: 10px
  --socials-bg-color: #E9E5E4
  --socials-bg-color--nth2: #E9E5E4
  --socials-bg-color--nth3: #E9E5E4
  --socials-margin: 16px
  --socials-fill: #{$color-pastel}
  --socials-fill--nth2: #{$color-pastel}
  --socials-fill--nth3: #{$color-pastel}

  --gallery-grid-margin: 0 -4px
  --gallery-col-padding: 0 4px
  --gallery-col-margin: 8px
  --gallery-image-border-radius: 10px
  --gallery-pagination-margin: 2px
  --gallery-pagination-bullet-size: 8px
  --gallery-pagination-bullet-color: #DDC9BE
  --gallery-pagination-bullet-margin: 0 5px
  --gallery-pagination-bullet-color--active: #{$color-pastel-secondary}

  --spoiler-border: none
  --spoiler-border-bottom: none
  --spoiler-border-top: none
  --spoiler-border-radius: 0
  --spoiler-bg-color: transparent
  --spoiler-title-font-weight: 400
  --spoiler-title-font-size: 16px
  --spoiler-title-line-height: 1.25
  --spoiler-title-padding: 6px 8px 8px
  --spoiler-title-bg-color: #fff
  --spoiler-title-border-radius: 10px
  --spoiler-title-color: #949A8D
  --spoiler-arrow-fill: #949A8D
  --spoiler-hidden-font-size: 16px
  --spoiler-hidden-line-height: 1.25
  --spoiler-hidden-padding: 5px 8px
  --spoiler-hidden-border-top: none
  --spoiler-hidden-color: #949A8D

  --commercial-bg-color: #{$color-pastel-bg}
  --commercial-border: 1px solid #{$color-pastel-secondary}
  --commercial-border-radius: 10px

  --button-font-weight: 700
  --button-font-size: 20px
  --button-line-height: 1.25
  --button-bg-color: #{$color-pastel}
  --button-color: #F5F5FB
  --button-border-radius: 10px
  --button-height: 50px
  --button-border: 2px solid #{$color-pastel}
  --button-color__hover: #F5F5FB
  --button-bg-color__hover: #{$color-pastel-hover}
  --button-border__hover: #{$color-pastel-hover}
  --button-color__active: #F5F5FB
  --button-bg-color__active: #{$color-pastel-active}
  --button-border__active: #{$color-pastel-active}
  --button-bg-color--secondary: #{$color-pastel-bg}
  --button-height--secondary: 50px
  --button-font-size--secondary: 20px
  --button-line-height--secondary: 1.25
  --button-color--secondary: #{$color-pastel-secondary}
  --button-border--secondary: 2px solid #{$color-pastel-secondary}
  --button-bg-color--secondary__hover: #{$color-pastel-bg}
  --button-color--secondary__hover: #{$color-pastel-secondary-hover}
  --button-border--secondary__hover: 2px solid #{$color-pastel-secondary-hover}
  --button-bg-color--secondary__active: #{$color-pastel-bg}
  --button-color--secondary__active: #{$color-pastel-secondary-active}
  --button-border--secondary__active: 2px solid #{$color-pastel-secondary-active}
  --button-desc-font-weight: 400
  --button-desc-color: #{$color-pastel-text}
  --button-desc-line-height: 1.3
  --button-desc-margin: 8px auto 0

  --title-font-size: 24px
  --title-line-height: 1.25
  --title-margin: 16px
  --title-font-weight: 700
  --title-color: #{$color-pastel-secondary}

  --subtitle-font-size: 20px
  --subtitle-line-height: 1.25
  --subtitle-margin: 8px
  --subtitle-font-weight: 700
  --subtitle-color: #{$color-pastel-secondary}

  --text-font-size: 16px
  --text-line-height: 1.25
  --text-margin: 8px

  --link-color: #{$color-pastel-secondary}
  --link-text-decoration: underline
  --link-text-decoration__hover: none

  --timer-font-weight: 700
  --timer-font-size: 16px
  --timer-line-height: 1.25
  --timer-color: #{$color-pastel}
  --timer-font-weight--md: 700
  --timer-font-size--md: 24px
  --timer-line-height--md: 1.25
  --timer-font-weight--lg: 700
  --timer-font-size--lg: 32px
  --timer-line-height--lg: 1.25

  --image-border-radius: 10px

  .editor__component
    background-color: var(--page-bg-color)

// /PASTEL
