.pfm-divider
  border-top: 2px dotted #c2c2c2
  margin: 16px 0 24px

  &--mobile
    margin-top: 6px
    order: 0

    &:first-of-type
      @media (min-width: 992px)
        display: none