.page-switcher
  margin: 0 -16px

  @media (min-width: 576px)
    margin: 0

  &__wrapper
    position: relative
    width: 320px
    margin: 0 auto
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    background-color: #000
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24)

    @media (min-width: 1200px)
      width: 368px
      height: 741px
      padding: 20px
      border: 4px solid $color-gray
      box-shadow: none
      border-radius: 56px

  &__page-wrapper
    height: 100%
    width: 100%
    overflow: auto
    -ms-overflow-style: none
    scrollbar-width: none

    @media (min-width: 1200px)
      border-radius: 34px

    &::-webkit-scrollbar
      display: none

    & .pfm-page
      min-height: 100%

  &__slider
    padding-bottom: 20px

    @media (min-width: 1200px)
      padding-bottom: 0
      margin-bottom: 20px

    & > .swiper-pagination
      position: static
      margin: 5px 0 20px
