.load-button
  min-height: 32px
  min-width: 103px
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  font-weight: 600
  padding: 4px 16px
  background-color: #ddd
  transition: $bootstrap-btn-transition
  border-radius: 4px
  margin: 0
  cursor: pointer

  &-wrapper
    display: flex
    align-items: center

  &:hover
    background-color: #c8c8c8

  &--avatar
    font-weight: 500
    width: 72px
    height: 72px
    min-width: 72px
    border-radius: 50%
    font-size: 36px
    line-height: 36px

  &--multiple
    font-weight: 500
    background-color: transparent
    font-size: 36px
    line-height: 36px
    transition: transform 0.35S ease-out

    &:hover
      background-color: transparent

      & span
        background-color: #c8c8c8

    & div
      border: 1px solid #DDD
      border-radius: 4px
      display: flex
      height: 100%
      width: 100%
      align-items: center
      justify-content: center
      background-color: #fff

    & span
      transition: $bootstrap-btn-transition
      display: inline-flex
      align-items: center
      justify-content: center
      background-color: #ddd
      border-radius: 50%
      width: 48px
      height: 48px

  & span
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis