.page-settings
  position: fixed
  bottom: 0
  left: 0
  right: 0
  overflow: auto
  max-height: calc(100% - 24px)
  min-height: 352px
  max-width: 360px
  margin: 0 auto
  background-color: #fff
  border-top-left-radius: 16px
  border-top-right-radius: 16px
  padding: 16px 23px 24px
  display: flex
  flex-direction: column

  @media (min-width: 992px)
    top: 0
    margin: auto
    max-height: 300px
    min-height: auto
    border-radius: 16px

  &__title
    font-size: 16px
    line-height: 22px
    font-weight: 500
    text-transform: uppercase
    margin-bottom: 17px
    display: flex
    align-items: center
    justify-content: space-between
    min-height: 40px

  &__form
    display: flex
    flex-direction: column
    flex: 1 1 auto

    &__path
      display: flex
      align-items: center

      &__domain
        margin-right: 4px
        font-size: 12px
        line-height: 16px

    .form-control.field, .field
      max-width: 100%

    &__buttons-wrapper
      margin-top: auto

      & > :first-child
        margin-right: 8px