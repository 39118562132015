.divider-radios

  &__group
    position: relative
    margin-bottom: 12px

  &__input

    margin-top: 0
    margin-left: 0
    left: 0
    top: 0
    opacity: 0
    z-index: -999

    &--chosen+.divider-radios__label:before
      background-image: url("../../assets/radio-checked.svg")
      background-size: 8px
      background-position: center
      background-repeat: no-repeat

  &__label
    display: flex
    min-height: 14px
    align-items: center
    padding-left: 26px
    position: relative

    &:before
      content: ''
      position: absolute
      border-radius: 50%
      box-sizing: border-box
      width: 14px
      height: 14px
      top: 0
      bottom: 0
      left: 0
      margin: auto 0
      border: 1px solid #888

    &__wrapper
      width: 100%

      .divider
        border-color: #000
        opacity: 1