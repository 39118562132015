.password-popup
  position: fixed
  background-color: #fff
  max-width: 320px
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  border-radius: 16px

  @media (min-width: 768px)
    max-width: 360px

  &-container
    padding: 0 28px 54px

  .default__header
    margin-bottom: 40px
    border-top-left-radius: 16px
    border-top-right-radius: 16px

  .profile__form
    margin-bottom: 0
    &__save-btn
      min-width: 264px

  &__title
    font-size: 20px
    line-height: 27px
    font-weight: 700
    text-transform: uppercase
    margin-bottom: 17px
    min-height: 40px
    text-align: center

  &__success
    display: flex
    justify-content: center
    align-items: center
    height: 150px
    width: 100%
    text-align: center

    &-text
      margin-bottom: 0
      font-weight: 500
      font-size: 24px
      line-height: 30px
