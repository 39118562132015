.prompt-popup
  background-color: #fff
  border-radius: 16px
  padding: 16px
  max-width: 328px
  min-height: 192px
  display: flex
  flex-direction: column
  justify-content: space-between

  &__message
    text-align: center
    font-size: 16px
    line-height: 20px
    font-weight: 500
    margin-top: 32px
    margin-bottom: 18px

  &__buttons-wrapper
    display: flex
    justify-content: center
    margin-bottom: 24px

  &__button
    margin: 0 4px
    min-height: 40px
    height: 40px
    min-width: 96px

    &--cancel.primary-btn
      color: #000

      &:hover, &:focus, &:active
        background-color: #eeeeee !important
        border-color: #eee!important
        color: #000!important

      &:focus
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05) !important