.slider

  &__arrow
    display: none
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    border-radius: 50%
    background-color: #ddd
    position: absolute
    top: calc(50% + 5px)
    z-index: 10
    cursor: pointer

    @media (min-width: 1200px)
      display: flex

    &--disabled
      opacity: 0.5
      cursor: default

    &--prev
      left: 0
      right: auto

    &--next
      left: auto
      right: 0

      img
        transform: rotate(180deg)

  &__pagination__bullet
    display: inline-block
    width: 12px
    height: 12px
    border-radius: 50%
    background-color: #c4c4c4
    margin: 0 4px

    &--active
      background-color: #000

  &__slide-clickable
    cursor: pointer
