.sidebar
  margin: 0
  padding: 0
  background-color: rgba(0, 0, 0, 0.5)
  width: 100%
  height: calc(100vh - 56px)
  position: fixed
  top: 56px
  left: -100%
  z-index: 3

  &--opened
    transform: translateX(100%)

  @media (min-width: 1200px)
    left: 0
    width: 240px

    &--opened
      transform: translateX(0)

  &__nav
    padding: 16px
    width: 240px
    height: 100%
    background-color: #fff
    display: block
    overflow: auto
    border-right: 1px solid #ddd

  &__link
    display: flex
    color: #000
    border-radius: 6px
    align-items: center
    height: 40px
    box-sizing: border-box
    padding: 0 8px
    font-size: 16px
    font-weight: 400
    margin-bottom: 4px
    transition: $bootstrap-bg-transition

    &:hover
      text-decoration: none
      color: #000

      &:not(.sidebar__link--disabled)
        background-color: #EBEEF2

    &--disabled
      opacity: 0.5

    & span, &--small span
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    &--small
      font-size: 14px
      height: 32px
      margin-bottom: 0
      width: 178px
      padding: 0 8px

      & .sidebar__link__icon
        margin-right: 2px

    &.sidebar__link--active
      background-color: #EBEEF2

  &__link__icon
    min-width: 18px
    display: flex
    justify-content: center
    margin-right: 12px

  &__dropdown

    &__head
      cursor: pointer

    &__body
      max-height: 0
      padding: 0
      opacity: 0
      z-index: -1
      position: absolute
      display: flex
      flex-direction: column
      align-items: flex-end

    &--opened .sidebar__dropdown__body
      max-height: 100%
      padding-bottom: 4px
      opacity: 1
      z-index: 0
      position: static