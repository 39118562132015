.custom-switch

  & > *
    cursor: pointer

  .custom-control-label

    &:before
      background-color: #c4c4c4
      border-color: #c4c4c4

    &:after
      background-color: #fff

  .custom-control-input

    &:checked ~ .custom-control-label::before
      background-color: $color-green
      border-color: $color-green

    &:focus

      & ~ .custom-control-label::before
        box-shadow: 0 0 0 0.2rem rgba(106, 188, 80, 0.25)

      &:not(:checked) ~ .custom-control-label::before
        border-color: #c4c4c4