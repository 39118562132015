.news

  &__item
    word-break: break-word

    &__back
      margin-bottom: 18px

      &__link
        color: $color-blue
        font-size: 16px
        line-height: 22px

        & span
          letter-spacing: 0.03em

        &:hover
          color: $color-blue

    &__date
      margin-bottom: 8px

    &__title
      font-size: 16px
      line-height: 22px
      font-weight: 700
      letter-spacing: 0.03em
      margin-bottom: 16px