.block-switcher
  margin: 0 -23px 32px

  @media (min-width: 1200px)
    margin: 0 auto 32px

  .swiper-slide
    margin-top: auto
    margin-bottom: auto
    max-width: 240px

    @media (min-width: 1200px)
      max-width: 100%

  .slider__arrow
    top: calc(50% - 18px)

  &__options-count
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    font-weight: 600
    min-height: 24px
    margin-bottom: 12px

  &__option
    height: 100%
    max-width: 240px
    margin: 0 auto
    font-family: 'Roboto', sans-serif