.custom-dropdown-menu
  position: absolute
  top: 32px
  right: 0
  display: none
  padding-top: 6px

  &--opened
    display: flex
    z-index: 1

  &__list
    background-color: #fff
    min-width: 112px
    flex-direction: column
    border: 1px solid #ccc
    border-radius: 6px
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2)
    padding: 10px
    list-style: none
    margin: 0

    &__item
      color: #000
      display: flex
      align-items: center
      justify-content: center

      & > *
        display: inline
        text-align: center
        color: #000
        width: 100%
        padding: 6px 10px
        font-size: 16px
        line-height: 18px
        background-color: transparent
        border: none
        border-radius: 6px

        &:hover
          color: #000
          text-decoration: none
          background-color: #EBEEF2