.stats

  &__page-title
    text-transform: unset

  &__item
    border: 1px solid #cccccc
    border-radius: 4px
    padding: 12px 16px
    margin-bottom: 16px

    @media (min-width: 768px)
      margin-bottom: 24px

    &--no-border
      border: none
      padding: 0

    &__title
      font-weight: 500
      font-size: 16px
      line-height: 22px

      & .pfm-anchor
        text-decoration: none

        &:hover
          text-decoration: underline

    &__content

      &__placeholder
        display: flex
        justify-content: center
        align-items: center
        min-height: 240px

  &__table

    &--mt12
      margin-top: 12px

    &__head
      font-weight: 700
      display: flex
      justify-content: space-between
      margin-bottom: 8px

    &__body
      list-style: none
      padding: 0
      margin: 0

      &__item
        min-height: 24px
        display: flex
        justify-content: space-between
        align-items: center

        & > .pfm-anchor
          text-decoration: none
          overflow: hidden
          text-overflow: ellipsis
          margin-right: 5px

          &:hover
            text-decoration: underline