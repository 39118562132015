.file-list
  display: flex
  flex-direction: row
  flex-wrap: wrap
  overflow: hidden
  margin: 0 -4px
  max-width: 312px

  & > *
    width: 100%
    max-width: 104px
    padding: 0 4px 8px
    height: 86px

  &__item
    height: 100%
    border-radius: 4px
    border: 1px solid #ccc
    display: flex
    align-items: center
    justify-content: space-between
    overflow: hidden
    position: relative
    background-color: #fff

    &__image
      object-fit: cover
      width: 100%
      height: 100%

    &__delete
      position: absolute
      top: 2px
      right: 2px

//.file-list
//  padding: 0
//  margin: 0
//  list-style: none
//  display: flex
//  flex-direction: column
//
//  &__loading
//    margin-top: 12px
//
//  &__item
//    padding: 0
//    margin-bottom: 8px
//    display: flex
//    align-items: center
//    justify-content: space-between
//    width: 100%
//    max-width: 328px
//    background-color: #fff
//
//    &:first-child
//      margin-top: 12px
//
//    &__preview
//      display: flex
//      align-items: center
//
//      &__image
//        width: 100%
//        height: 100%
//        object-fit: cover
//
//        &-wrapper
//          width: 32px
//          height: 24px
//          display: flex
//          align-items: center
//          justify-content: center
//          margin-right: 12px
//          background-color: #ccc
//
//      &__name
//        font-weight: 400
//        max-width: 164px
//        overflow: hidden
//        text-overflow: ellipsis
//        white-space: nowrap
//
//    &__delete
//      appearance: none
//      box-shadow: none
//      border: none
//      background-color: transparent
//      padding: 0
//      color: red
//
//      &:focus
//        outline: none
