.rich-text-editor
  max-width: 100%
  margin-bottom: 18px

  .title, .subtitle, .text
    font-family: 'Roboto', sans-serif

  .ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky
    position: static
    box-shadow: none
    width: 100% !important
    margin-left: 0 !important
    border-width: 0
    top: unset

    .ck-toolbar
      border-bottom-width: 0
      border-top-left-radius: var(--ck-border-radius) !important
      border-top-right-radius: var(--ck-border-radius) !important

  .ck.ck-content.ck-editor__editable
    min-height: 200px

  .ck.ck-sticky-panel__placeholder
    display: none!important

  .ck.ck-labeled-field-view.ck-labeled-field-view_empty:not(.ck-labeled-field-view_focused):not(.ck-labeled-field-view_placeholder)>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label
    transform: translate(var(--ck-spacing-medium), 0.4rem) scale(1)

  .ck.ck-input-text
    min-width: auto

//Themes

.rich-text-editor--atoms

  .title, .subtitle, .text
    font-family: 'Inter', sans-serif

  .link
    color: $color-atoms

.rich-text-editor--tiffany

  .title, .subtitle
    color: $color-tiffany

  .subtitle
    font-size: 20px
    line-height: 23px

  .text
    font-size: 16px
    line-height: 19px

  .link
    color: $color-tiffany
    text-decoration: underline

    &:hover
      text-decoration: none

.rich-text-editor--graphite

  .title, .subtitle
    font-family: 'Rubik', sans-serif

  .text
    font-family: 'Droid Sans', sans-serif

  .link
    color: #fff
    text-decoration: underline

    &:hover
      text-decoration: none

.rich-text-editor--clouds

  .title, .subtitle, .text
    font-family: 'Open Sans', sans-serif

  .title
    font-size: 32px
    line-height: 44px
    font-weight: 700
    margin-bottom: 10px

  .subtitle
    font-size: 26px
    line-height: 35px
    margin-bottom: 10px

  .text
    font-size: 16px
    line-height: 22px
    margin-bottom: 10px

  .link
    color: $color-clouds-secondary

.rich-text-editor--autumn

  .title, .subtitle, .text
    font-family: 'PT Serif', sans-serif

  .title
    font-size: 32px
    line-height: 42px
    margin-bottom: 6px

  .subtitle
    font-size: 26px
    line-height: 34px
    margin-bottom: 12px

  .text
    font-size: 16px
    line-height: 21px
    margin-bottom: 12px

  .link
    color: #6271FF

.rich-text-editor--pink

  .title, .subtitle, .text
    font-family: 'Lato', sans-serif

  .title, .subtitle
    color: $color-pink-dark
    font-weight: 700
    margin-bottom: 8px

  .title
    font-size: 26px
    line-height: 31px
    margin-bottom: 16px

  .subtitle
    font-size: 20px
    line-height: 24px

  .text
    font-size: 16px
    line-height: 19px

  .link
    color: $color-pink

.rich-text-editor--eco

  .title, .subtitle, .text
    font-family: 'Cera Pro', sans-serif
    color: #585760

  .title, .subtitle
    font-weight: 700
    color: $color-eco-dark
    margin-bottom: 8px

  .title
    font-size: 26px
    line-height: 33px
    margin-bottom: 16px

  .subtitle
    font-size: 20px
    line-height: 25px

  .text
    font-size: 16px
    line-height: 20px
    font-weight: 500

  .link
    color: $color-eco

.rich-text-editor--mosaic

  .title, .subtitle, .text
    font-family: 'Manrope', sans-serif
    color: $color-mosaic-text

  .title, .subtitle
    font-weight: 700
    color: $color-mosaic-dark

  .title
    font-size: 32px
    line-height: 44px
    margin-bottom: 12px

  .subtitle
    font-size: 24px
    line-height: 33px
    margin-bottom: 2px

  .text
    font-size: 18px
    line-height: 25px
    margin-bottom: 12px

  .link
    color: $color-mosaic

.rich-text-editor--aurora

  .title, .subtitle, .text
    font-family: 'Montserrat', sans-serif

  .subtitle, .title
    font-weight: 700

  .title
    font-size: 32px
    line-height: 39px
    margin-bottom: 12px

  .subtitle
    font-size: 24px
    line-height: 29px
    margin-bottom: 9px

  .text
    font-weight: 500
    font-size: 18px
    line-height: 22px
    margin-bottom: 9px

  .link
    color: $color-aurora-secondary

.rich-text-editor--minimal

  .title, .subtitle, .text
    color: $color-minimal-text
    font-family: 'Noto Sans', sans-serif

  .subtitle, .title
    color: $color-minimal
    font-weight: 700

  .title
    font-size: 24px
    line-height: 33px
    margin-bottom: 16px

  .subtitle
    font-size: 18px
    line-height: 25px
    margin-bottom: 9px

  .text
    font-weight: 500
    font-size: 14px
    line-height: 19px
    margin-bottom: 9px

  .link
    color: $color-minimal
    text-decoration: underline

    &:hover
      text-decoration: none

.rich-text-editor--pastel

  .title, .subtitle, .text
    color: $color-pastel-text
    font-family: 'Source Sans Pro', sans-serif

  .subtitle, .title
    color: $color-pastel-secondary
    font-weight: 700

  .title
    font-size: 24px
    line-height: 30px
    margin-bottom: 16px

  .subtitle
    font-size: 20px
    line-height: 25px
    margin-bottom: 8px

  .text
    font-size: 16px
    line-height: 20px
    margin-bottom: 8px

  .link
    color: $color-pastel-secondary
    text-decoration: underline

    &:hover
      text-decoration: none