.radio-tabs
  display: flex
  position: relative
  min-height: 32px
  border: 1px solid #ccc
  border-radius: 4px
  overflow: hidden
  max-width: 328px

  &__input
    top: 0
    left: 0
    opacity: 0
    z-index: -999

    &--chosen+.radio-tabs__label
      background-color: #ddd

      &:hover
        background-color: #ddd

  &__label
    background-color: #fff
    display: flex
    justify-content: center
    align-items: center
    border-right: 1px solid #ccc
    transition: $bootstrap-btn-transition
    cursor: pointer
    font-weight: 600

    &:hover
      background-color: #eee

    &:last-child
      border-right: none

  &__button
    background-color: #fff
    display: flex
    justify-content: center
    align-items: center
    border-top: none
    border-left: none
    border-right: 1px solid #ccc
    border-bottom: none
    padding: 0
    appearance: none
    box-shadow: none
    transition: $bootstrap-btn-transition
    font-size: 14px
    line-height: 19px
    font-weight: 600

    &--chosen
      background-color: #eee

    &:active
      color: #000

    &:focus
      outline: none

    &:hover
      background-color: #eee

    &:last-child
      border-right: none

    &:disabled
      color: #000