.select-device
  display: flex
  justify-content: center
  align-items: center
  width: 36px
  height: 48px
  cursor: pointer
  appearance: none
  border: none
  padding: 0

  &:focus
    outline: none

  &:hover, &:disabled
    background-color: #fff

  &--desktop
    display: none

    @media (min-width: 1328px)
      display: flex

  &--tablet, &--mobile
    display: none

    @media (min-width: 576px)
      display: flex