.cropper
  background-color: #fff
  margin: auto
  width: 100%
  max-width: 576px
  height: 100%
  max-height: 100%
  padding: 42px 16px 16px
  display: flex
  flex-direction: column
  position: relative

  @media (min-width: 576px)
    padding: 42px 42px 16px
    height: 600px
    border-radius: 8px

  &__wrapper
    position: relative
    width: 100%
    border: 1px solid #bbb
    border-radius: 8px
    overflow: hidden
    flex: 1 1 auto

  &__close-wrapper
    position: absolute
    top: 7px
    right: 10px

    @media (min-width: 576px)
      top: 10px
      right: 13px

  &__btn-wrapper
    padding-top: 16px
    display: flex

    & > :first-child
      margin-right: 8px