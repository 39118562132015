.picker
  position: relative
  .picker__popover
    z-index: 999

  .picker__popover--left
    left: inherit
    //right: 0

  &__swatch-container
    display: flex
    align-items: center
    border: 1px solid #CCCCCC
    border-radius: 4px
    box-sizing: border-box
    width: 99px
    max-height: 32px
    cursor: pointer
    margin-top: 4px
    &--selected
      border-color: #0038FF

  &__swatch
    width: 70px
    height: 24px
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)
    margin: 4px
    &--selected
      border: 1px solid #CCCCCC
      border-radius: 4px

  &__popover
    position: absolute
    top: calc(100% + 2px)
    left: 0
    border-radius: 9px
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15)

  &__title
    font-size: 14px
    font-weight: 600
