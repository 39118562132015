.primary-btn
  min-height: 48px
  padding: 8px 16px
  display: flex
  align-items: center
  text-align: center
  justify-content: center
  font-weight: 600
  background-color: $color-green
  border-color: $color-green
  max-width: 100%
  border-radius: 4px

  &--small
    font-weight: 500
    font-size: 0.875rem
    min-height: 40px

  &:focus
    box-shadow: 0 0 0 0.2rem rgba(106, 188, 80, 0.25) !important

  &:disabled, &.disabled
    opacity: 0.5

  &:disabled,
  &.disabled,
  &:hover,
  &:active,
  &:focus
    background-color: $color-lightgreen !important
    border-color: $color-lightgreen !important


.secondary-btn
  font-weight: 400
  border-radius: 4px
  padding: 5px 15px

.blue-btn
  background-color: #2F80ED

.blue-btn--published
  background-color: #0038FF

.light-btn--published
  background-color: rgba(255, 255, 255, 0.24)
  border-color: rgba(0, 0, 0, 0.24)
  padding: 4px 15px
  font-weight: 600

.light-btn
  border-color: #ddd
  background-color: #ddd
  padding: 4px 15px
  font-weight: 600

.outline-light-btn
  border: 1px solid #ccc
  color: #000

  &:disabled, &.disabled
    color: #000

  &:hover, &:active
    border-color: #ccc !important

  &:focus
    box-shadow: 0 0 0 0.2rem rgb(225, 225, 225)

.close-btn
  border: none
  appearance: none
  background-color: #ddd
  width: 24px
  height: 24px
  padding: 0
  border-radius: 4px
  transition: $bootstrap-btn-transition
  display: flex
  align-items: center
  justify-content: center

  &:hover
    background-color: #ccc

  &:focus
    outline: none

  & svg > *
    fill: #222222

  &--danger
    background-color: #fff
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)

    &:hover
      background-color: #eee

    & svg > *
      fill: red

.copy-btn
  appearance: none
  padding: 0
  border: none
  background-color: transparent
  box-shadow: none
  &.copy
    .copied-icon
      display: none
  &.copied
    .copy-link
      display: none
  &.copy-btn-absolute
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    span
      right: 8px
      top: calc(50% - (24px/2))
      position: absolute

  &:focus
    outline: none